import { createModel } from '@rematch/core';
import posthog from 'posthog-js';
import {
  activateBillingV2API,
  fetchPricingPreviewAPI,
  PlanChange,
} from 'src/lib/api/pricing';
import { logErrorToSentry } from 'src/lib/debug-utils';
import { handleRedirect } from 'src/lib/utils';
import { RootModel } from 'src/store/models';
import { PlanUpdateType, PreviewPlan } from './types';

export type PricingPreviewState = {
  isFetching: boolean;
  type?: PlanUpdateType;
  isTrialCancellation?: boolean;
  currentPlan?: PreviewPlan;
  scheduledPlan?: PreviewPlan;
  newPlan?: PreviewPlan;
  payableAmount?: string;
  nextPaymentDate?: string;
};

const initialState: PricingPreviewState = {
  isFetching: true,
};

export const pricingPreview = createModel<RootModel>()({
  state: initialState,
  effects: dispatch => ({
    async fetchPricingPreview({ payload }: { payload: PlanChange }) {
      dispatch.pricingPreview.setState({
        isFetching: true,
      });
      const { data, error } = await fetchPricingPreviewAPI(payload);

      if (error) {
        dispatch.saveToast.showError(
          (error as { message?: string })?.message ??
            'Error loading pricing preview',
        );
        logErrorToSentry({ error });
        return;
      }

      dispatch.pricingPreview.setState({
        isFetching: false,
        type: data.type,
        isTrialCancellation: data.is_trial_cancellation,
        currentPlan: data.current_plan,
        scheduledPlan: data.scheduled_plan,
        newPlan: data.new_plan,
        payableAmount: data.payable_amount,
        nextPaymentDate: data.next_payment_date,
      });
    },

    async activateNewPlan({ payload }: { payload: PlanChange }) {
      posthog.capture('Pricing Preview CTA Clicked');

      const { data, error } = await activateBillingV2API(payload);

      const { redirect_url } = data;
      if (redirect_url) {
        handleRedirect(redirect_url);
      }

      if (error) {
        if (typeof error === 'object' && error?.message) {
          const errorMessage = error.message;
          dispatch.saveToast.showError(errorMessage);
          logErrorToSentry({ error: errorMessage });
        } else {
          dispatch.saveToast.showError('Error updating subscription');
          logErrorToSentry({ error: 'Error updating subscription' });
        }
      }
    },
  }),
  reducers: {
    setState(state, payload: Partial<PricingPreviewState>) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export default pricingPreview;
