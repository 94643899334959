import { createModel } from '@rematch/core';
import {
  fetchAllIntegrationsAPI,
  postJudgeMeAPI,
  postRechargeAPI,
  saveIntegrationConfigAPI,
} from 'src/lib/api/integrations';
import { logErrorToSentry } from 'src/lib/debug-utils';
import { RootModel } from 'src/store/models';

interface IntegrationsState {
  isFetching: boolean;
  integrations: Array<AnyObject>;
  loadingRecharge: boolean;
  loadingJudgeMe: boolean;
}

const initialState = (): IntegrationsState => ({
  isFetching: true,
  integrations: null,
  loadingRecharge: false,
  loadingJudgeMe: false,
});

const integrationsModel = createModel<RootModel>()({
  state: initialState(),

  effects: dispatch => ({
    async fetch() {
      const res = await fetchAllIntegrationsAPI();
      if (!res.error) {
        this.storeIntegrations(res.data);
      }
    },
    async setRecharge(payload: string) {
      dispatch.integrations.setState({ loadingRecharge: true });
      const { data, error } = await postRechargeAPI(payload);
      dispatch.integrations.setState({ loadingRecharge: false });

      if (error) {
        dispatch.saveToast.showError('Activation unsuccessful');
        logErrorToSentry({
          error,
        });
      }

      if (data == 'success') {
        dispatch.saveToast.showDone('Activation successful');
        return data;
      }
    },

    async setJudgeMe() {
      dispatch.integrations.setState({ loadingJudgeMe: true });
      const { data, error } = await postJudgeMeAPI();
      const { redirect_url } = data;
      if (redirect_url) {
        window.location.href = redirect_url;
      }
      dispatch.integrations.setState({ loadingJudgeMe: false });

      if (error) {
        dispatch.saveToast.showError('Activation unsuccessful');

        logErrorToSentry({
          error,
        });
      }

      if (data) {
        dispatch.saveToast.showDone('Activation successful');

        return data;
      }
    },

    async updateStatus(
      { handle, status }: { handle: string; status: 'enabled' | 'disabled' },
      rootState,
    ) {
      const integration = rootState.integrations.integrations.find(
        i => i.handle === handle,
      );

      const data = { config: { ...integration.config, status } };
      this.storeIntegration({ handle, data });

      const res = await saveIntegrationConfigAPI({
        handle,
        config: data.config,
      });

      if (!res.error) {
        dispatch.saveToast.showDone('Changes saved');
      } else {
        dispatch.saveToast.showError(`Error updating status`);
        data.config.status = status === 'enabled' ? 'disabled' : 'enabled';
        this.storeIntegration({ handle, data });
      }
    },
  }),

  reducers: {
    storeIntegrations(state: IntegrationsState, payload) {
      return {
        ...state,
        ...payload,
        isFetching: false,
      };
    },

    storeIntegration(state: IntegrationsState, payload) {
      const newIntegrations = [...state.integrations];
      const index = newIntegrations.findIndex(i => i.handle === payload.handle);
      newIntegrations[index] = { ...newIntegrations[index], ...payload.data };

      return {
        ...state,
        integrations: newIntegrations,
      };
    },

    setState(state, payload: Partial<IntegrationsState>) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export default integrationsModel;
