import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import middlewares from './middleware';
import models, { RootModel } from './models';

export const RESET_APP = 'RESET_APP';

const store = init<RootModel>({
  models,
  redux: {
    middlewares,
    rootReducers: { [RESET_APP]: () => undefined },
  },
  plugins: [],
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type StoreState = RematchRootState<RootModel>;

export default store;
