import { createModel } from '@rematch/core';
import { isSmallScreen } from 'src/lib/utils';
import { RootModel } from '.';

export type UTMMedium =
  | 'top_nav'
  | 'profile_dropdown'
  | 'abandoned_cart'
  | 'browse_abandonment'
  | 'flash_sale'
  | 'hero_image'
  | 'segmentation'
  | 'shipping_notification'
  | 'smart_delivery'
  | 'impression_milestone_banner'
  | 'impression_milestone_popup'
  | 'impression_milestone_email'
  | 'post_campaign_overconsumed_popup'
  | 'topup'
  | 'upgrade_tile'
  | 'integrated_onboarding'
  | 'integrated_onboarding_modal'
  | 'locked_logo'
  | 'reinstall_modal'
  | 'profile_banner'
  | 'optin_logo_block'
  | 'optin_wheel_block'
  | null;

interface CommonState {
  isSidebarOpen: boolean;
  isSmallScreen: boolean;
  utmMedium: UTMMedium;
  utmSource: string | null;
}

const initialState = (): CommonState => ({
  isSidebarOpen: false,
  isSmallScreen: isSmallScreen(),
  utmMedium: null,
  utmSource: null,
});

const common = createModel<RootModel>()({
  state: initialState(),

  reducers: {
    setIsSmallScreen(state: CommonState, isSmallScreen: boolean): CommonState {
      return {
        ...state,
        isSmallScreen,
      };
    },

    toggleSidebar(state: CommonState): CommonState {
      return {
        ...state,
        isSidebarOpen: !state.isSidebarOpen,
      };
    },

    setUTM(
      state: CommonState,
      payload: { utmMedium: UTMMedium; utmSource: string | null },
    ): CommonState {
      return {
        ...state,
        utmMedium: payload.utmMedium,
        utmSource: payload.utmSource,
      };
    },
  },
});

export default common;
