/* eslint-disable no-restricted-syntax */
import { ChannelType } from 'src/lib/constants';
import { roundOffTwoDecimals } from 'src/lib/utils';
import { PricingV3State, SelectedPricingSlabSku } from './models';
import {
  ChargeType,
  CouponCodeRule,
  Plan,
  PlanType,
  PricingCategory,
  PricingPlans,
  PricingSlab,
  RecommendedPlans,
} from './models/types';

export const getPricingSlabs = ({
  plans,
  planType,
  chargeType,
  channel,
}: {
  plans?:
    | PricingPlans['email']
    | PricingPlans['webpush']
    | PricingPlans['omni'];
  chargeType: ChargeType;
  planType: PlanType;
  channel?: ChannelType;
}): PricingSlab[] => {
  const allSlabs = plans?.[chargeType]?.[planType]?.[0]?.pricing_slabs || [];

  if (channel) {
    return allSlabs.filter(slab => slab.channel === channel);
  }

  return allSlabs;
};

export const getDefaultPricingSlabs = (
  pricingPlans: Parameters<typeof getPricingSlabs>[0]['plans'],
  recommendedPlans?: RecommendedPlans,
): SelectedPricingSlabSku => {
  const getPlans = (
    planType: PlanType,
    channel: ChannelType,
    chargeType: ChargeType,
  ) =>
    getPricingSlabs({
      plans: pricingPlans,
      planType,
      chargeType,
    }).find(slab => slab.channel === channel);

  // monthly basic
  const monthlyFreeEmailPlan = getPlans('free', ChannelType.EMAIL, 'monthly');
  const monthlyFreeWebpushPlan = getPlans(
    'free',
    ChannelType.WEBPUSH,
    'monthly',
  );
  const monthlyFreeSMSPlan = getPlans('free', ChannelType.SMS, 'monthly');

  // monthly business
  const monthlyFirstWebpushBusinessPlan = getPlans(
    'business',
    ChannelType.WEBPUSH,
    'monthly',
  );
  const monthlyFirstEmailBusinessPlan = getPlans(
    'business',
    ChannelType.EMAIL,
    'monthly',
  );
  const monthlyFirstSMSBusinessPlan = getPlans(
    'business',
    ChannelType.SMS,
    'monthly',
  );

  // monthly enterprise
  const monthlyFirstWebpushEnterprisePlan = getPlans(
    'enterprise',
    ChannelType.WEBPUSH,
    'monthly',
  );
  const monthlyFirstEmailEnterprisePlan = getPlans(
    'enterprise',
    ChannelType.EMAIL,
    'monthly',
  );
  const monthlyFirstSMSEnterprisePlan = getPlans(
    'enterprise',
    ChannelType.SMS,
    'monthly',
  );

  // annual basic
  const annualFreeEmailPlan = getPlans('free', ChannelType.EMAIL, 'annual');
  const annualFreeWebpushPlan = getPlans('free', ChannelType.WEBPUSH, 'annual');
  const annualFreeSMSPlan = getPlans('free', ChannelType.SMS, 'annual');

  // annual business
  const annualFirstWebpushBusinessPlan = getPlans(
    'business',
    ChannelType.WEBPUSH,
    'annual',
  );
  const annualFirstEmailBusinessPlan = getPlans(
    'business',
    ChannelType.EMAIL,
    'annual',
  );
  const annualFirstSMSBusinessPlan = getPlans(
    'business',
    ChannelType.SMS,
    'annual',
  );

  // annual enterprise
  const annualFirstWebpushEnterprisePlan = getPlans(
    'enterprise',
    ChannelType.WEBPUSH,
    'annual',
  );
  const annualFirstEmailEnterprisePlan = getPlans(
    'enterprise',
    ChannelType.EMAIL,
    'annual',
  );
  const annualFirstSMSEnterprisePlan = getPlans(
    'enterprise',
    ChannelType.SMS,
    'annual',
  );

  const selectedSlabs: SelectedPricingSlabSku = {
    monthly: {
      free: {
        email: monthlyFreeEmailPlan?.sku || '',
        webpush: monthlyFreeWebpushPlan?.sku || '',
        sms: monthlyFreeSMSPlan?.sku || '',
      },
      business: {
        email: monthlyFirstEmailBusinessPlan?.sku || '',
        webpush: monthlyFirstWebpushBusinessPlan?.sku || '',
        sms: monthlyFirstSMSBusinessPlan?.sku || '',
      },
      enterprise: {
        email: monthlyFirstEmailEnterprisePlan?.sku || '',
        webpush: monthlyFirstWebpushEnterprisePlan?.sku || '',
        sms: monthlyFirstSMSEnterprisePlan?.sku || '',
      },
    },
    annual: {
      free: {
        email: annualFreeEmailPlan?.sku || '',
        webpush: annualFreeWebpushPlan?.sku || '',
        sms: annualFreeSMSPlan?.sku || '',
      },
      business: {
        email: annualFirstEmailBusinessPlan?.sku || '',
        webpush: annualFirstWebpushBusinessPlan?.sku || '',
        sms: annualFirstSMSBusinessPlan?.sku || '',
      },
      enterprise: {
        email: annualFirstEmailEnterprisePlan?.sku || '',
        webpush: annualFirstWebpushEnterprisePlan?.sku || '',
        sms: annualFirstSMSEnterprisePlan?.sku || '',
      },
    },
  };

  // preselect recommended plans
  if (recommendedPlans) {
    if (recommendedPlans.monthly) {
      const recomendedWebpushSlab = recommendedPlans.monthly.pricing_slabs.find(
        slab => slab.channel === ChannelType.WEBPUSH,
      );
      if (recomendedWebpushSlab) {
        selectedSlabs.monthly[recommendedPlans.monthly.plan_type].webpush =
          recomendedWebpushSlab.sku;
      }

      const recomendedEmailSlab = recommendedPlans.monthly.pricing_slabs.find(
        slab => slab.channel === ChannelType.EMAIL,
      );
      if (recomendedEmailSlab) {
        selectedSlabs.monthly[recommendedPlans.monthly.plan_type].email =
          recomendedEmailSlab.sku;
      }

      const recomendedSMSSlab = recommendedPlans.monthly.pricing_slabs.find(
        slab => slab.channel === ChannelType.SMS,
      );
      if (recomendedSMSSlab) {
        selectedSlabs.monthly[recommendedPlans.monthly.plan_type].sms =
          recomendedSMSSlab.sku;
      }
    }

    if (recommendedPlans.annual) {
      const recomendedWebpushSlab = recommendedPlans.annual.pricing_slabs.find(
        slab => slab.channel === ChannelType.WEBPUSH,
      );
      if (recomendedWebpushSlab) {
        selectedSlabs.annual[recommendedPlans.annual.plan_type].webpush =
          recomendedWebpushSlab.sku;
      }

      const recomendedEmailSlab = recommendedPlans.annual.pricing_slabs.find(
        slab => slab.channel === ChannelType.EMAIL,
      );
      if (recomendedEmailSlab) {
        selectedSlabs.annual[recommendedPlans.annual.plan_type].email =
          recomendedEmailSlab.sku;
      }

      const recomendedSMSSlab = recommendedPlans.annual.pricing_slabs.find(
        slab => slab.channel === ChannelType.SMS,
      );
      if (recomendedSMSSlab) {
        selectedSlabs.annual[recommendedPlans.annual.plan_type].sms =
          recomendedSMSSlab.sku;
      }
    }
  }

  return selectedSlabs;
};

export const isSelectedSlabLessThanCurrentSlab = (
  currentPricingSlab: PricingSlab,
  selectedPricingSlab: PricingSlab,
) => {
  if (currentPricingSlab && selectedPricingSlab) {
    return (
      currentPricingSlab.attribution_limit >
      selectedPricingSlab.attribution_limit
    );
  }

  return false;
};

export const canApplyCouponCode = ({
  appliesTo,
  current,
}: {
  appliesTo: CouponCodeRule['appliesTo'];
  current: {
    planType: PlanType;
    chargeType: ChargeType;
    billingPlanSku: string;
    pricingSlabSku: string;
  };
}) => {
  const isMonthly = current.chargeType === 'monthly';
  if (appliesTo.plans?.length > 0) {
    // If SKU exists it takes priority and other rule will be ignored
    return (
      appliesTo.plans.includes(current.billingPlanSku) ||
      appliesTo.plans.includes(current.pricingSlabSku)
    );
  }

  const hasPlanType = appliesTo.planTypes.includes(current.planType);

  if (appliesTo.billingInterval === 'monthly') {
    if (appliesTo.planTypes.length > 0) {
      return hasPlanType && isMonthly;
    }
    return isMonthly;
  }

  if (appliesTo.billingInterval === 'annual') {
    if (appliesTo.planTypes.length > 0) {
      return hasPlanType && !isMonthly;
    }
    return !isMonthly;
  }

  if (appliesTo.planTypes.length > 0) return hasPlanType;

  return false;
};

export const getDiscountPrice = ({
  price,
  coupon,
}: {
  price: string;
  coupon: CouponCodeRule;
}) => {
  const { amountOff, percentageOff, discountType } = coupon;
  const parsedPrice = parseFloat(price);

  if (discountType === 'percentage') {
    return roundOffTwoDecimals(
      parsedPrice - (parsedPrice * percentageOff) / 100,
    );
  }

  if (discountType === 'fixed_amount') {
    const discountedPrice = parsedPrice - amountOff;
    return discountedPrice < 0
      ? roundOffTwoDecimals(0)
      : roundOffTwoDecimals(discountedPrice);
  }

  return roundOffTwoDecimals(parsedPrice);
};

export const switchToAppliedCouponPlan = ({
  appliedTo,
  plans,
}: {
  appliedTo: CouponCodeRule['appliesTo'];
  plans: PricingPlans;
}): { chargeType?: ChargeType; category: PricingCategory } => {
  const res = {
    chargeType: undefined,
    category: undefined,
  };

  if (appliedTo.plans?.length > 0) {
    const sku = appliedTo.plans[0];
    const billingPlan = getTopLevelBillingPlanBySku(sku, plans);

    if (billingPlan) {
      let billingPlanCategory: PricingCategory;
      // this is the only way to determine the category of the billing plan
      if (billingPlan.sku.includes('omni')) {
        billingPlanCategory = 'omni';
      } else if (billingPlan.sku.includes('webpush')) {
        billingPlanCategory = 'webpush';
      } else if (billingPlan.sku.includes('email')) {
        billingPlanCategory = 'email';
      }

      res.category = billingPlanCategory;
      res.chargeType = billingPlan.charge_type;
    }

    return res;
  }

  if (appliedTo.billingInterval) {
    res.chargeType =
      appliedTo.billingInterval === 'all' ? '' : appliedTo.billingInterval;
  }

  return res;
};

/**
 * given the billing plan configuration, returns the top level billing plan
 */
export const getTopLevelBillingPlanByConfig = ({
  category,
  plans,
  chargeType,
  planType,
  isTrial = false,
}: {
  category: PricingCategory;
  plans: PricingPlans;
  chargeType: ChargeType;
  planType: PlanType;
  isTrial?: boolean;
}): Plan => {
  try {
    const { webpush, omni, email, trialPlans } = plans;

    switch (category) {
      case 'webpush': {
        const topLevelBillingPlan = webpush[chargeType][planType][0];
        return topLevelBillingPlan;
      }
      case 'omni': {
        const topLevelPlan = isTrial ? trialPlans.omni : omni;
        const topLevelBillingPlan = topLevelPlan[chargeType][planType][0];
        return topLevelBillingPlan;
      }
      case 'email': {
        const topLevelBillingPlan = email[chargeType][planType][0];
        return topLevelBillingPlan;
      }
      default:
        return null;
    }
  } catch {
    return null;
  }
};

/**
 * given a sku, returns the top level billing plan
 * !!! this is an expensive function !!!
 */
export const getTopLevelBillingPlanBySku = (
  sku: string,
  plans: PricingPlans,
) => {
  const allCategories: PricingCategory[] = ['webpush', 'omni', 'email'];
  const allChargeTypes: ChargeType[] = ['monthly', 'annual'];
  const allPlanTypes: PlanType[] = ['enterprise', 'business', 'free'];

  // this is the only readable way to accumulate all billing plans :(
  for (const category of allCategories) {
    for (const chargeType of allChargeTypes) {
      for (const planType of allPlanTypes) {
        const topLevelBillingPlan = getTopLevelBillingPlanByConfig({
          category,
          plans,
          chargeType,
          planType,
        });

        if (topLevelBillingPlan?.sku === sku) {
          return topLevelBillingPlan;
        }
      }
    }
  }

  return null;
};

export const getSlabForNewChargeType = (
  category: PricingCategory,
  oldChargeType: ChargeType,
  newChargeType: ChargeType,
  channel: ChannelType,
  selectedPricingSlabSku: string,
  plans: PricingPlans,
  planType: PlanType,
): PricingSlab | undefined => {
  const oldChargeTypeSlabs: PricingSlab[] = getPricingSlabs({
    plans: category === 'omni' ? plans.omni : plans.email,
    chargeType: oldChargeType,
    planType,
  }).filter(slab => slab.channel === channel);

  const oldChargeTypeSlabIndex = oldChargeTypeSlabs.findIndex(
    slab => slab.sku === selectedPricingSlabSku,
  );

  if (oldChargeTypeSlabIndex === -1) return undefined;

  const newChargeTypeSlabs: PricingSlab[] = getPricingSlabs({
    plans: category === 'omni' ? plans.omni : plans.email,
    chargeType: newChargeType,
    planType,
  }).filter(slab => slab.channel === channel);

  return newChargeTypeSlabs[oldChargeTypeSlabIndex];
};

export const handleEmailPricingSlabChange = ({
  emailSlabs,
  pricing,
  stepBy,
  isPricingForOnboarding = false,
  planType,
  chargeType,
}: {
  emailSlabs: PricingSlab[];
  pricing: PricingV3State['pricing'] | PricingV3State['onboardingPricing'];
  stepBy: number;
  isPricingForOnboarding?: boolean;
  planType?: PlanType;
  chargeType?: ChargeType;
}) => {
  const currentIndex = isPricingForOnboarding
    ? emailSlabs.findIndex(slab => slab.sku === pricing.selectedPricingSlabSku)
    : emailSlabs.findIndex(
        slab =>
          slab.sku ===
          pricing.selectedPricingSlabSku[chargeType][planType].email,
      );

  let newIndex;
  let shouldShowContactUs = false;

  if (pricing.shouldShowContactUsCTA && stepBy === -1) {
    newIndex = emailSlabs.length - 1;
  } else {
    newIndex = currentIndex + stepBy;
    if (newIndex >= emailSlabs.length) {
      shouldShowContactUs = true;
      newIndex = emailSlabs.length - 1;
    } else if (newIndex < 0) {
      newIndex = 0;
    }
  }

  const newSku = emailSlabs[newIndex].sku;

  return newSku
    ? {
        newSku,
        shouldShowContactUs,
      }
    : null;
};

export const isCurrentPricingSlabSelected = ({
  currentPlan,
  currentSlab,
  channel,
}: {
  currentPlan: PricingV3State['plans']['current'];
  currentSlab: PricingSlab;
  channel: ChannelType;
}) => {
  return (
    currentPlan?.pricing_slabs?.find(slab => slab.channel === channel)?.sku ===
    currentSlab?.sku
  );
};

export const isRecommendedPricingSlabSelected = ({
  currentPricingSlab,
  recommendedPlans,
  chargeType,
  planType,
}: {
  currentPricingSlab: PricingSlab;
  recommendedPlans: RecommendedPlans;
  chargeType: ChargeType;
  planType: PlanType;
}) => {
  if (!currentPricingSlab || !recommendedPlans) return false;

  const recommendedPlan = recommendedPlans[chargeType];

  if (recommendedPlan?.plan_type !== planType) return false;

  return (
    recommendedPlan?.pricing_slabs.find(
      slab => slab.channel === currentPricingSlab.channel,
    )?.sku === currentPricingSlab.sku
  );
};

export const getRecommendedPricingSlab = ({
  recommendedPlans,
  chargeType,
  planType,
  channel,
}: {
  recommendedPlans: RecommendedPlans;
  chargeType: ChargeType;
  planType: PlanType;
  channel: ChannelType;
}) => {
  if (!recommendedPlans) return null;

  const recommendedPlan = recommendedPlans[chargeType];

  if (recommendedPlan?.plan_type !== planType) return null;

  return recommendedPlan?.pricing_slabs.find(slab => slab.channel === channel);
};

export const doesPricingSlabExistBeforeAfter = ({
  plans,
  chargeType,
  planType,
  channel,
  currentSlabSku,
}: {
  plans: PricingPlans;
  chargeType: ChargeType;
  planType: PlanType;
  channel: ChannelType;
  currentSlabSku: string;
}) => {
  const channelSlabs = getPricingSlabs({
    plans: plans.omni,
    chargeType,
    planType,
  }).filter(slab => slab.channel === channel);

  const currentSlabIndex = channelSlabs.findIndex(
    slab => slab.sku === currentSlabSku,
  );

  const previousSlab = channelSlabs[currentSlabIndex - 1];
  const nextSlab = channelSlabs[currentSlabIndex + 1];

  return {
    previousSlab,
    nextSlab,
  };
};

export const getEquivalentMonthlyPricingSlab = ({
  plans,
  category,
  planType,
  annualPricingSlab,
}: {
  plans: PricingPlans;
  category: PricingCategory;
  planType: PlanType;
  annualPricingSlab: PricingSlab;
}): PricingSlab | null => {
  if (annualPricingSlab?.subscription_cycle !== 'annual') {
    return null;
  }

  const topLevelMonthlyBillingPlan = getTopLevelBillingPlanByConfig({
    plans,
    category,
    chargeType: 'monthly',
    planType,
  });

  const monthlyPricingSlab = topLevelMonthlyBillingPlan?.pricing_slabs.find(
    (slab: PricingSlab) =>
      slab.channel === annualPricingSlab.channel &&
      slab.attribution_limit === annualPricingSlab.attribution_limit,
  );
  return monthlyPricingSlab;
};

export const getAnnualPriceWithoutFixedDiscount = ({
  plans,
  category,
  planType,
  annualPricingSlab,
}: {
  plans: PricingPlans;
  category: PricingCategory;
  planType: PlanType;
  annualPricingSlab: PricingSlab;
}): string | null => {
  const monthlyPricingSlab = getEquivalentMonthlyPricingSlab({
    plans,
    category,
    planType,
    annualPricingSlab,
  });

  if (!monthlyPricingSlab) {
    return null;
  }

  switch (monthlyPricingSlab.channel) {
    case ChannelType.EMAIL:
      return roundOffTwoDecimals(parseFloat(monthlyPricingSlab.price) * 12);
    case ChannelType.WEBPUSH:
      return roundOffTwoDecimals(parseFloat(monthlyPricingSlab.price) * 12);
    case ChannelType.SMS:
      return roundOffTwoDecimals(parseFloat(monthlyPricingSlab.price) * 12);
    default:
      return '0.00';
  }
};
