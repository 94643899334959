import { finder } from '@medv/finder';
import { capitalize } from 'src/lib/utils';
import { v1 as uuidv1 } from 'uuid';
import {
  DefaultDateFormat,
  ReservedElementNodeId,
  SHIFTABLE_NODE_IDS,
} from '../components/FormWidget/lib/constants';
import {
  BaseTreeNode,
  TreeNode,
  TreeNodeByType,
  TreeNodeType,
} from '../components/FormWidget/lib/types';
import { CustomAttribute, CustomElementType } from '../models/types';

/**
 * applies changes to a node in a tree in a mutable way, creates a new tree and returns it
 * although it seems like we mutate the node, the `applyChangesToNodeTree` creates a new node out of the mutated node.
 * This is directly inspired by how [immer](https://immerjs.github.io/immer/produce) returns new data after performing mutable operations inside a callback
 *
 * *API usage:*
 * ```
 * applyChangesToNodeTree(
 *   'nodeId',
 *   (node) => {
 *     node.attr.someAttr = someValue;
 *     node.children = someValue
 *   },
 *   tree
 *  );
 * ```
 */
export const applyChangesToNodeTree = (
  nodeId: string,
  callback: (node: TreeNode) => void,
  tree: TreeNode,
): TreeNode => {
  const cloneNode = (node: TreeNode): TreeNode => {
    if (node.id === nodeId) {
      callback(node);
    }
    if (
      node.children &&
      Array.isArray(node.children) &&
      // only box has children as of now
      node.type === TreeNodeType.BOX
    ) {
      return {
        ...node,
        children: node.children.map(child => cloneNode(child)),
      };
    }
    return { ...node };
  };
  return cloneNode(tree);
};

/**
 * walking through the tree and applying the callback to each node
 *
 * *API usage:*
 * ```
 * depthFirstTraversal(
 *   (node) => {
 *     // Perform changes on the node
 *     node.attr.someAttr = someValue;
 *   },
 *   tree
 * );
 * ```
 */
export const depthFirstTraversal = (
  callback: (node: TreeNode) => void,
  tree: TreeNode,
): TreeNode => {
  const cloneNode = (node: TreeNode): TreeNode => {
    callback(node);

    if (
      node.children &&
      Array.isArray(node.children) &&
      node.type === TreeNodeType.BOX
    ) {
      return {
        ...node,
        children: node.children.map(child => cloneNode(child)),
      };
    }
    return { ...node };
  };
  return cloneNode(tree);
};

function isTreeNode(node: BaseTreeNode): node is TreeNode {
  return 'type' in node;
}

/**
 * searches for a node in a tree(`node`) by its id, goes to a maximum depth of 8,
 * if a tree is more than 8 levels deep, we might have a serious problem on the way we structure our tree
 */
export const getTreeNodeById = (
  node: TreeNode | TreeNode[],
  id: string,
  n = 8,
  doesIdsMatch?: (currentId, nodeIdToMatch) => boolean,
): TreeNode | null => {
  if (!Array.isArray(node)) {
    if (doesIdsMatch ? doesIdsMatch(node?.id, id) : node?.id === id) {
      return node;
    }

    if (n === 0 || !node?.children) {
      return null; // If depth is 0 or node has no children, stop searching
    }

    const children = Array.isArray(node?.children)
      ? node?.children
      : [node?.children];
    // enabling for loops
    // eslint-disable-next-line no-restricted-syntax
    for (const child of children) {
      if (typeof child !== 'string' && isTreeNode(child)) {
        const foundNode = getTreeNodeById(child, id, n - 1, doesIdsMatch);
        if (foundNode) {
          return foundNode;
        }
      }
    }
  }

  return null;
};

export const createCustomFieldTreeNode = (
  attribute: CustomAttribute,
): TreeNodeByType<
  | TreeNodeType.INPUT
  | TreeNodeType.DATE
  | TreeNodeType.CATEGORY
  | TreeNodeType.MULTI_SELECT
> => {
  const smallUUID = uuidv1().split('-')[0];
  switch (attribute.type) {
    case 'float':
    case 'text':
      return {
        id: `${ReservedElementNodeId.CUSTOM_FIELD}-${smallUUID}`,
        type: TreeNodeType.INPUT,
        attr: {
          color: '#000',
          placeholder: `Enter your ${attribute.name}`,
          fieldType: attribute.type === 'float' ? 'number' : 'text',
          mapAttribute: attribute.name,
          required: true,
        },
        children: capitalize(attribute.name.toLowerCase()).replace('_', ' '),
      };
    case 'date':
      return {
        id: `${ReservedElementNodeId.CUSTOM_FIELD}-${smallUUID}`,
        type: TreeNodeType.DATE,
        attr: {
          color: '#000',
          placeholder: DefaultDateFormat,
          fieldType: attribute.type,
          mapAttribute: attribute.name,
          dateFormat: DefaultDateFormat,
          required: true,
        },
        children: capitalize(attribute.name.toLowerCase()).replace('_', ' '),
      };
    case 'category':
      return {
        id: `${ReservedElementNodeId.CUSTOM_FIELD}-${smallUUID}`,
        type: TreeNodeType.CATEGORY,
        attr: {
          mapAttribute: attribute.name,
          required: true,
          showLabel: true,
          label: attribute.name,
          inputType: 'dropdown',
          defaultOption: attribute.options[0],
          attributeType: 'category',
          orientation: 'vertical',
        },
        children: attribute.options,
      };

    case 'multiple-choice':
      return {
        id: `${ReservedElementNodeId.CUSTOM_FIELD}-${smallUUID}`,
        type: TreeNodeType.MULTI_SELECT,
        attr: {
          mapAttribute: attribute.name,
          required: true,
          showLabel: true,
          label: attribute.name,
          attributeType: 'multiple-choice',
          orientation: 'vertical',
        },
        children: attribute.options,
      };
    default:
      return null;
  }
};

export const parseCustomOptinHTML = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const body = doc?.body;

  const textOnlyInputs: HTMLElement[] = Array.from(
    body?.querySelectorAll(
      'input:not([type=radio]), input:not([type=checkbox])',
    ),
  );

  const buttons: HTMLElement[] = Array.from(body?.querySelectorAll('button'));

  return {
    textOnlyInputs,
    buttons,
  };
};

export const getCustomMappableActions = (
  mappedActions: TreeNodeByType<TreeNodeType.HTML>['attr'],
): Record<
  CustomElementType,
  Array<{ value: string; label: string; disabled?: boolean }>
> => {
  const allActions: ReturnType<typeof getCustomMappableActions> = {
    button: [
      {
        value: 'submit',
        label: 'Submit opt-in',
      },
      {
        value: 'dismiss',
        label: 'Dismiss opt-in',
      },
    ],
    input: [
      {
        value: 'email',
        label: 'Email field',
      },
      {
        value: 'phone',
        label: 'Phone number field',
      },
    ],
  };

  return allActions;
};

export const isCustomElementValid = (
  element: HTMLElement,
  mappedActions: TreeNodeByType<TreeNodeType.HTML>['attr'],
  type: CustomElementType,
  DOMTree: Element,
) => {
  try {
    const selector = finder(element, { root: DOMTree });
    if (!selector) return false;

    const mappedActionsForType = mappedActions[type];

    if (!mappedActionsForType) return false;

    let isValid = false;

    Object.entries(mappedActionsForType).forEach(([_, actionValue]) => {
      Object.keys(actionValue).forEach(el => {
        if (el === selector) {
          isValid = true;
        }
      });
    });

    return isValid;
  } catch {
    return false;
  }
};

/**
 * returns the position of the media node in the root box node
 *
 * this function is a bit opinionated and assumes that media node exists as a child of root node
 * this might subject to change in the future
 */
export const getMediaNodePosition = (mediaNode: TreeNode, tree: TreeNode) => {
  const rootChildren = tree.children as TreeNode[];

  if (tree.type === TreeNodeType.BOX) {
    if (tree.attr.bgUrl) {
      return 'background';
    }

    if (tree.attr.dir === 'LR') {
      const imageNodeIndex = rootChildren.findIndex(
        child => child.id === mediaNode.id,
      );

      if (imageNodeIndex === 0) {
        return 'left';
      }

      if (imageNodeIndex === 1) {
        return 'right';
      }
    } else if (tree.attr.dir === 'TB') {
      const imageNodeIndex = rootChildren.findIndex(
        child => child.id === mediaNode.id,
      );

      if (imageNodeIndex === 0) {
        return 'top';
      }
    }
  }

  return null;
};

/**
 * returns the direct single parent node in the tree
 */
export const getDirectParentNode = (
  node: TreeNode,
  root: TreeNode,
): TreeNode | null => {
  if (!root || !root.children || !Array.isArray(root.children) || !node)
    return null;

  // eslint-disable-next-line no-restricted-syntax
  for (const child of root.children) {
    if (typeof child === 'string') return null;
    if (child.id === node.id) return root;
    if (child.type === TreeNodeType.BOX) {
      const parent = getDirectParentNode(node, child);
      if (parent) return parent;
    }
  }

  return null;
};

/**
 * returns the selectable nodes for a given node
 * returns the node's siblings, direct child nodes and root siblings excluding the branch containing the node
 */
export const getSelectableNodes = (
  node: TreeNode,
  root: TreeNode,
): TreeNode[] => {
  const parent = getDirectParentNode(node, root);

  let siblings: TreeNode[] = [];
  if (parent) {
    if (
      typeof parent.children !== 'string' &&
      parent.children &&
      parent.type !== TreeNodeType.HTML
    ) {
      siblings = parent.children.filter((child): child is TreeNode => {
        if (typeof child === 'string') return false;
        return child.id !== node.id && !child.attr?.hidden;
      });
    }
  }

  const children =
    typeof node.children === 'string' ||
    !node.children ||
    node.type === TreeNodeType.HTML
      ? []
      : node.children.filter((child): child is TreeNode => {
          if (typeof child !== 'string') {
            return !child?.attr?.hidden;
          }
          return false;
        });

  // Get root siblings excluding the branch containing the node
  const rootSiblings =
    typeof root.children === 'string' ||
    !root.children ||
    root.type === TreeNodeType.HTML
      ? []
      : root.children.filter((child): child is TreeNode => {
          if (typeof child !== 'string') {
            let currentParent = parent;
            while (currentParent) {
              if (child.id === currentParent.id) return false;
              currentParent = getDirectParentNode(currentParent, root);
            }
            return !child?.attr?.hidden;
          }
          return false;
        });

  // Get parent's siblings
  const parentSiblings = parent
    ? (() => {
        const grandParent = getDirectParentNode(parent, root);
        if (
          !grandParent ||
          typeof grandParent.children === 'string' ||
          !grandParent.children ||
          grandParent.type === TreeNodeType.HTML
        ) {
          return [];
        }
        return grandParent.children.filter((child): child is TreeNode => {
          if (typeof child !== 'string') {
            return child.id !== parent.id && !child?.attr?.hidden;
          }
          return false;
        });
      })()
    : [];

  return [...siblings, ...children, ...rootSiblings, ...parentSiblings];
};

/**
 * for a give node inside a root, returns the neighbouring nodes and one level up and down nodes
 * aka node's siblings, parents and children
 */
export function getNeighbouredNodes(
  node: TreeNode,
  root: TreeNode,
): {
  siblings: TreeNode[];
  parentsAbove: TreeNode[];
  children: TreeNode[];
} {
  const parent = getDirectParentNode(node, root);
  if (!parent) return { siblings: [], parentsAbove: [], children: [] };

  let siblings = [];
  if (Array.isArray(parent.children)) {
    siblings = parent.children.filter(child => {
      if (typeof child !== 'string') {
        return child.id !== node.id && child.type === TreeNodeType.BOX;
      }
      return false;
    });
  }

  let parentsAbove = [];
  const grandParent = getDirectParentNode(parent, root);

  if (grandParent && Array.isArray(grandParent.children)) {
    parentsAbove = grandParent.children.filter(child => {
      if (typeof child !== 'string') {
        return child.id !== parent.id && child.type === TreeNodeType.BOX;
      }
      return false;
    });
  }

  let children = [];

  if (Array.isArray(node.children)) {
    children = node.children.filter(child => {
      if (typeof child !== 'string') {
        return child.type === TreeNodeType.BOX;
      }
      return false;
    });
  }

  return { siblings, parentsAbove, children };
}

export const canMoveNodeUp = (nodeId: string, tree: TreeNode) => {
  if (!SHIFTABLE_NODE_IDS.includes(nodeId)) return false;

  const node = getTreeNodeById(tree, nodeId);
  if (!node) return false;

  const parent = getDirectParentNode(node, tree);
  // filter out html nodes, because they have multiple children
  if (
    !parent ||
    !Array.isArray(parent.children) ||
    parent.type === TreeNodeType.HTML ||
    parent.type === TreeNodeType.CATEGORY ||
    parent.type === TreeNodeType.MULTI_SELECT
  )
    return false;

  const visibleChildren = parent.children.filter(child => {
    if (typeof child !== 'string') {
      return !child.attr?.hidden;
    }
    return false;
  });

  if (!visibleChildren.length) return false;

  return visibleChildren[0].id !== node.id;
};

export const canMoveNodeDown = (nodeId: string, tree: TreeNode) => {
  if (!SHIFTABLE_NODE_IDS.includes(nodeId)) return false;

  const node = getTreeNodeById(tree, nodeId);
  if (!node) return false;

  const parent = getDirectParentNode(node, tree);
  // filter out html nodes, because they have multiple children
  if (
    !parent ||
    !Array.isArray(parent.children) ||
    parent.type === TreeNodeType.HTML ||
    parent.type === TreeNodeType.CATEGORY ||
    parent.type === TreeNodeType.MULTI_SELECT
  ) {
    return false;
  }

  const visibleChildren = parent.children.filter(child => {
    if (typeof child !== 'string') {
      return !child.attr?.hidden;
    }
    return false;
  });

  if (!visibleChildren.length) return false;

  return visibleChildren[visibleChildren.length - 1].id !== node.id;
};
