import { createModel } from '@rematch/core';
import getT from 'next-translate/getT';
import Router from 'next/router';
import {
  cancelPlanAPI,
  changeEmailPlanAPI,
  changePlanAPI,
  fetchPricingV2API,
  validateCouponAPI,
} from 'src/lib/api/pricing';
import { ChannelType } from 'src/lib/constants';
import { logErrorToSentry } from 'src/lib/debug-utils';
import { handleRedirect } from 'src/lib/utils';
import { RootModel } from 'src/store/models';
import { UTMMedium } from 'src/store/models/common';
import {
  denormaliseWebpushPricingSlabs,
  getDefaultPricingSlabs,
  getPricingSlabs,
  getSlabForNewChargeType,
  getTopLevelBillingPlanByConfig,
  handleEmailPricingSlabChange,
  switchToAppliedCouponPlan,
} from '../utils';
import {
  ChargeType,
  CouponCodeRule,
  Plan,
  PlanType,
  PricingCategory,
  PricingPlans,
  PricingSlab,
  Topup,
} from './types';

type PricingV2State = {
  plans: {
    isFetching: boolean;
    current: Plan | null;
    trialPlan?: Plan;
    topups: Topup[];
  } & PricingPlans;
  pricing: {
    shouldShowContactUsCTA: boolean;
    selectedChargeType: ChargeType;
    selectedCategory: PricingCategory;
    isChangingPlan: boolean;
    selectedPricingSlabSku: {
      email: string;
      webpush: string;
      webpushOnlyIdx: number;
    };
  };
  coupon: {
    code: string;
    isFetching: boolean;
    expiryDate: string | null;
    rule: CouponCodeRule;
    isValid?: boolean;
    message?: string;
  };
  onboardingPricing: {
    shouldShowContactUsCTA: boolean;
    selectedChargeType: ChargeType;
    selectedPricingSlabSku: string;
    isChangingPlan: boolean;
  };
};

const defaultPlans = {
  monthly: {
    free: [],
    business: [],
    enterprise: [],
  },
  annual: {
    free: [],
    business: [],
    enterprise: [],
  },
};

const defaultCoupon: PricingV2State['coupon'] = {
  isFetching: false,
  expiryDate: null,
  code: null,
  isValid: true,
  message: '',
  rule: {
    discountType: 'percentage',
    percentageOff: 0,
    amountOff: 0,
    currency: '',
    durationInMonths: 0,
    duration: '',
    appliesTo: {
      planTypes: [],
      plans: [],
      billingInterval: 'all',
    },
  },
};

const initialStateV2: PricingV2State = {
  plans: {
    isFetching: false,
    webpush: defaultPlans,
    denormalisedWebpushOnlyPricingSlabs: {
      monthly: [],
      annual: [],
    },
    email: defaultPlans,
    omni: defaultPlans,
    current: null,
    topups: [],
  },
  pricing: {
    selectedCategory: 'omni',
    selectedChargeType: 'monthly',
    shouldShowContactUsCTA: false,
    isChangingPlan: false,
    selectedPricingSlabSku: {
      email: '',
      webpush: '',
      webpushOnlyIdx: 0,
    },
  },
  coupon: defaultCoupon,
  onboardingPricing: {
    shouldShowContactUsCTA: false,
    selectedChargeType: 'monthly',
    selectedPricingSlabSku: '',
    isChangingPlan: false,
  },
};

const pricingV2 = createModel<RootModel>()({
  state: initialStateV2,
  effects: dispatch => ({
    async fetchPricing(payload: { selectFreeEmailPlanByDefault?: boolean }) {
      dispatch.pricingV2.setPlans({
        isFetching: true,
      });

      const { data, error } = await fetchPricingV2API();
      if (error) {
        dispatch.saveToast.showError('Error loading pricing');
        return;
      }

      const { webpush: webpushOnlyPlans } = data.plans;

      const {
        annual: annualWebpushOnlySlabs,
        monthly: monthlyWebpushOnlySlabs,
      } = denormaliseWebpushPricingSlabs(webpushOnlyPlans);

      dispatch.pricingV2.setPlans({
        isFetching: false,
        webpush: data.plans.webpush || [],
        email: data.plans.email || [],
        omni: data.plans.omni || [],
        denormalisedWebpushOnlyPricingSlabs: {
          annual: annualWebpushOnlySlabs,
          monthly: monthlyWebpushOnlySlabs,
        },
        current: data.current_plan,
        trialPlan: data.trial_plan,
        topups: data.topups || [],
      });

      if (payload?.selectFreeEmailPlanByDefault) {
        const monthyFreeOmniPlan =
          data.plans?.omni?.monthly?.enterprise?.[0]?.pricing_slabs?.[0];

        dispatch.pricingV2.setOnboarding({
          selectedPricingSlabSku: monthyFreeOmniPlan?.sku,
        });
      }
    },

    async changeOnboardingPlan(payload: {
      sku: string;
      chargeType: ChargeType;
    }) {
      dispatch.pricingV2.setOnboarding({
        isChangingPlan: true,
      });

      const { data, error } = await changeEmailPlanAPI({
        email_pricing_slab_sku: payload.sku,
        charge_type: payload.chargeType,
      });

      if (error) {
        dispatch.saveToast.showError('Error changing plan');
      } else if (data.confirmation_url) {
        handleRedirect(data.confirmation_url);
      } else {
        // hard redirect to refetch the updated plan
        window.location.href = '/email';
      }

      dispatch.pricingV2.setOnboarding({
        isChangingPlan: false,
      });
    },

    async changeOnboardingPricingChargeType(payload: ChargeType, rootState) {
      const oldChargeType =
        rootState.pricingV2.onboardingPricing.selectedChargeType;
      const newChargeType = payload;

      dispatch.pricingV2.setOnboarding({
        selectedChargeType: payload,
      });

      dispatch.pricingV2.updateOnboardingAfterChangingChargeType({
        oldChargeType,
        newChargeType,
      });
    },

    updateOnboardingAfterChangingChargeType(
      payload: {
        oldChargeType: ChargeType;
        newChargeType: ChargeType;
      },
      rootState,
    ) {
      const { onboardingPricing, plans } = rootState.pricingV2;

      const {
        website: { flags },
      } = rootState.user.user;

      const isTierBasedPricingEnabled = flags.tier_based_pricing === 'enabled';

      const newChargeTypeSlab = getSlabForNewChargeType(
        isTierBasedPricingEnabled ? 'omni' : 'email',
        payload.oldChargeType,
        payload.newChargeType,
        ChannelType.EMAIL,
        onboardingPricing.selectedPricingSlabSku,
        plans,
      );

      if (newChargeTypeSlab) {
        dispatch.pricingV2.setOnboarding({
          selectedPricingSlabSku: newChargeTypeSlab.sku,
        });
      }
    },

    changeOnboardingPricingSlab(
      payload: {
        stepBy: 1 | -1;
      },
      rootState,
    ) {
      const { onboardingPricing, plans } = rootState.pricingV2;

      const pricingSlabs = getPricingSlabs({
        plans: plans.omni,
        chargeType: onboardingPricing.selectedChargeType,
        planType: 'enterprise',
      });
      const emailSlabs = pricingSlabs.filter(
        slab => slab.channel === ChannelType.EMAIL,
      );

      const result = handleEmailPricingSlabChange({
        emailSlabs,
        pricing: onboardingPricing,
        stepBy: payload.stepBy,
        isPricingForOnboarding: true,
      });

      if (result) {
        dispatch.pricingV2.setOnboarding({
          selectedPricingSlabSku: result.newSku,
          shouldShowContactUsCTA: result.shouldShowContactUs,
        });
      }
    },

    hasEmailPlan(_, rootState): boolean {
      const { plans } = rootState.pricingV2;

      const isBrevoMerchant =
        rootState.user.user.website.flags.brevo_merchant === 'enabled';

      if (isBrevoMerchant) {
        return true;
      }

      if (!plans.current) return false;

      return Boolean(
        plans.current.pricing_slabs.find(
          slab => slab.channel === ChannelType.EMAIL,
        ),
      );
    },

    preselectPricingSlab(_, rootState) {
      const { plans } = rootState.pricingV2;

      const selectedSlabs = getDefaultPricingSlabs(plans.current, plans);

      dispatch.pricingV2.setPricing({
        selectedChargeType: plans.current.charge_type,
        selectedPricingSlabSku: {
          email: selectedSlabs.email || '',
          webpush: selectedSlabs.webpush || '',
          webpushOnlyIdx: selectedSlabs.webpushOnlyIdx || 0,
        },
      });
    },

    async changePricingChargeType(payload: ChargeType, rootState) {
      const oldChargeType = rootState.pricingV2.pricing.selectedChargeType;
      const newChargeType = payload;

      dispatch.pricingV2.setPricing({
        selectedChargeType: newChargeType,
      });

      // update pricing slab for both email and omni, since charge type can be changed from
      // different category as well
      dispatch.pricingV2.updatePricingSlabAfterChangingChargeType({
        category: 'email',
        oldChargeType,
        newChargeType,
      });
      dispatch.pricingV2.updatePricingSlabAfterChangingChargeType({
        category: 'omni',
        oldChargeType,
        newChargeType,
      });
    },

    updatePricingSlabAfterChangingChargeType(
      payload: {
        category: PricingCategory;
        oldChargeType: ChargeType;
        newChargeType: ChargeType;
      },
      rootState,
    ) {
      const { pricing, plans } = rootState.pricingV2;

      switch (payload.category) {
        case 'email': {
          const newChargeTypeEmailSlab = getSlabForNewChargeType(
            payload.category,
            payload.oldChargeType,
            payload.newChargeType,
            ChannelType.EMAIL,
            pricing.selectedPricingSlabSku.email,
            plans,
          );

          if (newChargeTypeEmailSlab) {
            dispatch.pricingV2.setPricing({
              selectedPricingSlabSku: {
                ...pricing.selectedPricingSlabSku,
                email: newChargeTypeEmailSlab.sku,
              },
            });
          }
          break;
        }

        case 'omni': {
          const newChargeTypeEmailSlab = getSlabForNewChargeType(
            payload.category,
            payload.oldChargeType,
            payload.newChargeType,
            ChannelType.EMAIL,
            pricing.selectedPricingSlabSku.email,
            plans,
          );

          if (newChargeTypeEmailSlab) {
            dispatch.pricingV2.setPricing({
              selectedPricingSlabSku: {
                ...pricing.selectedPricingSlabSku,
                email: newChargeTypeEmailSlab.sku,
              },
            });
          }

          const newChargeTypeWebpushSlab = getSlabForNewChargeType(
            payload.category,
            payload.oldChargeType,
            payload.newChargeType,
            ChannelType.WEBPUSH,
            pricing.selectedPricingSlabSku.webpush,
            plans,
          );

          if (newChargeTypeWebpushSlab) {
            dispatch.pricingV2.setPricing({
              selectedPricingSlabSku: {
                ...pricing.selectedPricingSlabSku,
                webpush: newChargeTypeWebpushSlab.sku,
              },
            });
          }
          break;
        }

        default: {
          break;
        }
      }
    },

    async changePricingCategory(payload: PricingCategory) {
      dispatch.pricingV2.setPricing({
        selectedCategory: payload,
      });
    },

    changePricingSlab(
      payload: {
        stepBy: 1 | -1;
        category: PricingCategory | 'email-omni' | 'webpush-omni';
      },
      rootState,
    ) {
      const { pricing, plans } = rootState.pricingV2;

      switch (payload.category) {
        case 'webpush': {
          const webpushSlabs: PricingSlab[] =
            plans.denormalisedWebpushOnlyPricingSlabs[
              pricing.selectedChargeType
            ];

          const nextSlab =
            webpushSlabs[
              pricing.selectedPricingSlabSku.webpushOnlyIdx + payload.stepBy
            ];

          if (nextSlab) {
            dispatch.pricingV2.setPricing({
              selectedPricingSlabSku: {
                ...pricing.selectedPricingSlabSku,
                webpushOnlyIdx:
                  pricing.selectedPricingSlabSku.webpushOnlyIdx +
                  payload.stepBy,
              },
            });
          }
          break;
        }

        case 'email': {
          const emailSlabs: PricingSlab[] = getPricingSlabs({
            plans: plans.email,
            chargeType: pricing.selectedChargeType,
            planType: 'enterprise',
          });

          const result = handleEmailPricingSlabChange({
            emailSlabs,
            pricing,
            stepBy: payload.stepBy,
          });

          if (result) {
            dispatch.pricingV2.setPricing({
              selectedPricingSlabSku: {
                ...pricing.selectedPricingSlabSku,
                email: result.newSku,
              },
              shouldShowContactUsCTA: result.shouldShowContactUs,
            });
          }
          break;
        }

        case 'webpush-omni': {
          const webpushSlabs: PricingSlab[] = getPricingSlabs({
            plans: plans.omni,
            chargeType: pricing.selectedChargeType,
            planType: 'enterprise',
          }).filter(slab => slab.channel === ChannelType.WEBPUSH);
          const currentSlabIndex = webpushSlabs.findIndex(
            slab => slab.sku === pricing.selectedPricingSlabSku.webpush,
          );

          const nextSlab = webpushSlabs[currentSlabIndex + payload.stepBy];

          if (nextSlab) {
            dispatch.pricingV2.setPricing({
              selectedPricingSlabSku: {
                ...pricing.selectedPricingSlabSku,
                webpush: nextSlab.sku,
              },
            });
          }
          break;
        }

        case 'email-omni': {
          const emailSlabs: PricingSlab[] = getPricingSlabs({
            plans: plans.omni,
            chargeType: pricing.selectedChargeType,
            planType: 'enterprise',
          }).filter(slab => slab.channel === ChannelType.EMAIL);

          const result = handleEmailPricingSlabChange({
            emailSlabs,
            pricing,
            stepBy: payload.stepBy,
          });

          if (result) {
            dispatch.pricingV2.setPricing({
              selectedPricingSlabSku: {
                ...pricing.selectedPricingSlabSku,
                email: result.newSku,
              },
              shouldShowContactUsCTA: result.shouldShowContactUs,
            });
          }
          break;
        }

        default:
          break;
      }
    },

    async changePricingPlan(
      payload: {
        selectedSku: string | string[];
        topLevelBillingSku?: string;
        category: PricingCategory;
        plan_type?: PlanType;
        isTotalPriceZero?: boolean;
        utmMedium?: UTMMedium;
        utmSource?: string;
      },
      rootState,
    ) {
      dispatch.pricingV2.setPricing({
        isChangingPlan: true,
      });

      const {
        plans,
        pricing: { selectedChargeType },
        coupon,
      } = rootState.pricingV2;
      const { utmMedium, utmSource } = rootState.common;

      const topLevelBillingPlanSku = getTopLevelBillingPlanByConfig({
        plans,
        chargeType: selectedChargeType,
        category: payload.category,
        planType: payload.plan_type,
      });

      if (topLevelBillingPlanSku || payload.topLevelBillingSku) {
        if (payload.isTotalPriceZero) {
          const { error } = await cancelPlanAPI({
            billing_plan_sku:
              payload.topLevelBillingSku || topLevelBillingPlanSku?.sku,
            pricing_slab_skus: [].concat(payload.selectedSku),
          });

          if (error) {
            dispatch.saveToast.showError('Error changing plan');
          } else {
            // hard redirect to refetch the updated plan
            window.location.href = '/pricing';
          }
        } else {
          const { data, error } = await changePlanAPI({
            billing_plan_sku:
              payload.topLevelBillingSku || topLevelBillingPlanSku?.sku,
            pricing_slab_skus: [].concat(payload.selectedSku),
            coupon_code: coupon.code || null,
            utm_source: payload.utmSource || utmSource || 'pushowl',
            utm_medium: payload.utmMedium || utmMedium,
          });

          if (error) {
            dispatch.saveToast.showError('Error changing plan');
          } else if (data.confirmation_url) {
            handleRedirect(data.confirmation_url);
          }
        }
      } else {
        logErrorToSentry({
          error: 'No top level billing plan sku found',
          extras: {
            payload,
          },
        });
        dispatch.saveToast.showError('Error changing plan');
      }

      dispatch.pricingV2.setPricing({
        isChangingPlan: false,
      });
    },

    async validateCoupon({ code }: { code: string }) {
      const t = await getT(Router.locale, 'pricing');
      dispatch.pricingV2.setCoupon({
        isFetching: true,
      });

      const { error } = await validateCouponAPI({ code });

      if (error) {
        dispatch.pricingV2.setCoupon({
          isFetching: false,
          message: t('invalid_coupon_code_err_msg'),
          isValid: false,
        });
      } else {
        dispatch.pricingV2.setCoupon({
          message: '',
          isValid: true,
          isFetching: false,
        });
      }
    },

    async applyCoupon(payload: { code: string }, rootState) {
      const pricingT = await getT(Router.locale, 'pricing');

      dispatch.pricingV2.setCoupon({
        isFetching: true,
      });

      const { data, error } = await validateCouponAPI({
        code: payload.code,
      });

      if (error) {
        dispatch.pricingV2.setCoupon({
          ...defaultCoupon,
          message: pricingT('invalid_coupon_code_err_msg'),
          isValid: false,
        });
        return;
      }

      dispatch.saveToast.showDone('Coupon applied');

      const {
        code,
        expiry_date: expiryDate,
        rule: {
          discount_type: discountType,
          percentage_off,
          amount_off,
          currency,
          duration,
          duration_in_months,
          applies_to: {
            plan_types: planTypes,
            plans,
            billing_interval: billingInterval,
          },
        },
      } = data;

      const coupon: PricingV2State['coupon'] = {
        message: 'Discount applied',
        isValid: true,
        isFetching: false,
        code,
        expiryDate,
        rule: {
          discountType,
          percentageOff: parseFloat(percentage_off),
          amountOff: parseFloat(amount_off),
          currency,
          duration,
          durationInMonths: parseInt(duration_in_months, 10),
          appliesTo: {
            planTypes,
            plans,
            billingInterval,
          },
        },
      };

      dispatch.pricingV2.setCoupon(coupon);

      const { plans: pricingPlans, pricing } = rootState.pricingV2;

      const { chargeType } = switchToAppliedCouponPlan({
        appliedTo: coupon.rule.appliesTo,
        plans: pricingPlans,
      });

      dispatch.pricingV2.setPricing({
        selectedChargeType: chargeType || pricing.selectedChargeType,
      });
    },

    resetCoupon() {
      dispatch.pricingV2.setCoupon({ ...defaultCoupon });
    },

    goToPricing(payload: { utmMedium?: UTMMedium; utmSource?: string }) {
      dispatch.common.setUTM({
        utmMedium: payload?.utmMedium,
        utmSource: payload?.utmSource,
      });

      Router.push('/pricing');
    },

    async changeToTrialPlan(
      payload: { utmMedium?: UTMMedium; utmSource?: string },
      rootState,
    ) {
      const { trialPlan } = rootState.pricingV2.plans;

      if (trialPlan) {
        await dispatch.pricingV2.changePricingPlan({
          category: 'webpush',
          selectedSku: trialPlan.pricing_slabs?.[0]?.sku,
          topLevelBillingSku: trialPlan.sku,
          utmSource: payload.utmSource,
          utmMedium: payload.utmMedium,
        });
      } else {
        dispatch.pricingV2.goToPricing({
          utmMedium: payload.utmMedium,
          utmSource: payload.utmSource,
        });
      }
    },
  }),

  reducers: {
    setPlans(state, payload: Partial<PricingV2State['plans']>) {
      return {
        ...state,
        plans: {
          ...state.plans,
          ...payload,
        },
      };
    },
    setPricing(state, payload: Partial<PricingV2State['pricing']>) {
      return {
        ...state,
        pricing: {
          ...state.pricing,
          ...payload,
        },
      };
    },
    setCoupon(
      state: PricingV2State,
      payload: Partial<PricingV2State['coupon']>,
    ) {
      return {
        ...state,
        coupon: {
          ...state.coupon,
          ...payload,
        },
      };
    },
    setOnboarding(
      state,
      payload: Partial<PricingV2State['onboardingPricing']>,
    ) {
      return {
        ...state,
        onboardingPricing: {
          ...state.onboardingPricing,
          ...payload,
        },
      };
    },
  },
});

export default pricingV2;
