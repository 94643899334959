import { createModel } from '@rematch/core';
import Router from 'next/router';
import { changePlanAPI, PlanChange } from 'src/lib/api/pricing';
import { handleRedirect } from 'src/lib/utils';

export enum PromotionTypes {
  AbandonedCart = 'abandoned-cart',
  Shipping = 'shipping',
  HeroImage = 'hero-image',
  FlashSale = 'flash-sale',
  SmartDelivery = 'smart-delivery',
  Segmentation = 'segmentation',
  BrowserAbandonment = 'browser-abandonment',
  LockedLogo = 'locked-logo',
  OptinLogoBlock = 'optin-logo-block',
  OptinWheelBlock = 'optin-wheel-block',
}

interface State {
  isPlanChangeInProgress: boolean;
}

const initialState = () => ({
  isPlanChangeInProgress: false,
});

const promotionPopups = createModel()({
  state: initialState(),
  reducers: {
    setIsPlanChangeInProgress(state: State, payload): State {
      return {
        ...state,
        isPlanChangeInProgress: payload,
      };
    },
  },
  effects: dispatch => ({
    show(payload: PromotionTypes) {
      const url = new URL(window.location.href);
      url.searchParams.append('promotionType', payload);
      url.searchParams.delete('closedPromotionModal');
      Router.push(url.pathname + url.search);
    },
    hide() {
      const url = new URL(window.location.href);
      const closedPromotionModal = url.searchParams.get('promotionType');
      url.searchParams.delete('promotionType');
      url.searchParams.append('closedPromotionModal', closedPromotionModal);

      Router.push(url.pathname + url.search);
    },
    async changePlan(payload: PlanChange) {
      const res = await changePlanAPI(payload);

      if (!res.error) {
        this.hide();
        handleRedirect(res.data.confirmation_url);
      } else {
        dispatch.saveToast.showError('Error fetching pricing');
      }
    },
  }),
});

export default promotionPopups;
