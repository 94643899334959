import { createModel } from '@rematch/core';
import getT from 'next-translate/getT';
import Router from 'next/router';
import { fetchBannersAPI } from 'src/lib/api/misc';
import { RootModel } from 'src/store/models';

type BannerState = {
  banners: [
    {
      sequence: number;
      buttons: Array<{
        text: string;
        handler?: string;
        action?: string;
        url?: string;
        target?: string;
      }>;
      category: string;
      class: string;
      icon: string;
      title: string;
      paths: [string];
      description: string;
      shortname?: string;
    }?,
  ];
  isFetching: boolean;
  isAnimationDone: boolean;
};

async function processBanners(banners) {
  const t = await getT(Router.locale, 'banners');
  return banners.map(banner => {
    const descriptionTranslated = t(
      banner.description.replaceAll('.', '').replaceAll('%', ''),
      {},
      {
        fallback:
          banner.shortname === 'spf'
            ? 'stripe_payment_failed_desc_default'
            : null,
      },
    );
    return {
      ...banner,
      description: descriptionTranslated,
    };
  });
}

const banners = createModel<RootModel>()({
  state: {
    banners: [],
    isFetching: true,
    isAnimationDone: false,
  } as BannerState,

  reducers: {
    setState(state: BannerState, payload: Partial<BannerState>) {
      return {
        ...state,
        ...payload,
      };
    },
  },

  effects: () => ({
    async fetchBanners() {
      this.setState({ isFetching: true });
      const { data, error } = await fetchBannersAPI();

      const processedBanners = error ? [] : await processBanners(data);

      this.setState({ banners: processedBanners, isFetching: false });
    },
  }),
});

export default banners;
