import { createModel } from '@rematch/core';
import Router from 'next/router';
import {
  AllTimeTimeFilter,
  TimeFilter,
} from 'src/components/HistoricalDaterangePicker/types';
import {
  getSMSStatsAllTime,
  getSMSStatsTimeFilter,
  getStepAPI,
  setStepAPI,
} from 'src/lib/api/sms';
import { RootModel } from 'src/store/models';
import { SenderIdType, SMSStats } from './types';

type SmsModelState = {
  list: {
    country: string;
    value: string;
  }[];
  form_link: string;
  talon: string;
  onboarding: {
    countries: string[];
    phone_number: string;
    email: string;
  };
  credits: {
    country: string;
    credits: number;
    code: string;
    short_country: string;
  }[];
  step: {
    has_next: boolean;
    has_previous: boolean;
    name: string;
  };
  pricing: {
    price: string;
    credits: string;
    sku: string;
    url: string;
  }[];
  selected_pricing: {
    price: string;
    credits: string;
  };
  selected_price_data: {
    selected_slab: string;
    pricing_url: string;
    redirect: boolean;
  };
  target_audience: [];
  otp: string;
  tfn_status: string;
  compliance_type: string;
  isLoading: boolean;
  isOnboardingFinished: boolean;
  isNextLoading: boolean;
  isPreviousLoading: boolean;
  isResendLoading: boolean;
  isSkipLoading: boolean;
  isOnboardingCompleted: boolean;
  isDataSyncing: boolean;
  shouldAllowMerchantToCreateCampaign: boolean;
  sender_id_type: SenderIdType;
  revenueTimeFilter: TimeFilter;
  subscriberTimeFilter: TimeFilter;
  smsStats: SMSStats;
};

const initialState: SmsModelState = {
  revenueTimeFilter: AllTimeTimeFilter,
  subscriberTimeFilter: AllTimeTimeFilter,
  list: [],
  form_link: '',
  talon: '',
  onboarding: {
    countries: [],
    phone_number: '',
    email: '',
  },
  credits: [],
  step: {
    has_next: false,
    has_previous: false,
    name: '',
  },
  pricing: [],
  selected_pricing: {
    price: '',
    credits: '',
  },
  selected_price_data: {
    selected_slab: '',
    pricing_url: '',
    redirect: false,
  },
  target_audience: [],
  otp: '',
  tfn_status: '',
  compliance_type: 'toll-free',
  isLoading: false,
  isOnboardingFinished: false,
  isNextLoading: false,
  isPreviousLoading: false,
  isResendLoading: false,
  isSkipLoading: false,
  isOnboardingCompleted: false,
  isDataSyncing: false,
  shouldAllowMerchantToCreateCampaign: false,
  sender_id_type: 'default',
  smsStats: {
    campaign: {
      total_revenue: 0,
      attributed_revenue: [],
    },
    automation: {
      total_revenue: 0,
      attributed_revenue: [],
    },
  },
};

const sms = createModel<RootModel>()({
  state: initialState,

  effects: dispatch => ({
    async getStep({ navigate }: { navigate: boolean }, rootState) {
      dispatch.sms.setState({
        isLoading: true,
        isOnboardingFinished: true,
        isOnboardingCompleted: false,
      });

      const { data, error } = await getStepAPI();
      const { sms } = rootState;

      if (error) {
        dispatch.saveToast.showError('sms_onboarding_failed');
      }

      dispatch.sms.setState({
        step: {
          has_next: data.has_next,
          has_previous: data.has_previous,
          name: data.step,
        },
      });

      if (navigate) {
        if (data.step === 'finish') {
          if (Router.pathname !== '/sms') {
            Router.push('/sms');
          }
        } else {
          Router.push(`/sms/onboarding/${data.step}`);
        }
      }

      const current_step = data.step;

      switch (current_step) {
        case 'verify-store':
          dispatch.sms.setState({
            onboarding: {
              ...sms.onboarding,
              email: data.email,
            },
          });
          break;
        case 'verify-otp':
          dispatch.sms.setState({
            onboarding: {
              ...sms.onboarding,
              phone_number: data.phone_number,
            },
          });
          break;
        case 'target-audience':
          dispatch.sms.setState({
            onboarding: {
              ...sms.onboarding,
              countries: data.target_audience,
            },
            list: data.sms_countries.map(item => ({
              country: item.country,
              value: item.short_country,
            })),
          });
          break;
        case 'manage-compliance':
          dispatch.sms.setState({
            form_link: data.tfn_form_link,
          });
          break;
        case 'pricing':
          dispatch.sms.setState({
            credits: data.sms_countries.map(item => item),
            selected_price_data: {
              ...sms.selected_price_data,
              selected_slab:
                data.selected_sms_slab_sku || data.sms_pricing_slabs[0].sku,
            },
            pricing: data.sms_pricing_slabs.map(item => ({
              price: item.price,
              credits: item.attribution_limit,
              sku: item.sku,
            })),
          });
          if (data.sms_pricing_slabs.length > 0) {
            if (!data.selected_sms_slab_sku) {
              const firstSlab = data.sms_pricing_slabs[0];
              dispatch.sms.setState({
                selected_pricing: {
                  price: firstSlab.price,
                  credits: firstSlab.attribution_limit,
                },
                selected_price_data: {
                  ...sms.selected_price_data,
                  selected_slab: firstSlab.sku,
                },
              });
            } else {
              const selectedSlab = data.sms_pricing_slabs.find(
                item => item.sku === data.selected_sms_slab_sku,
              );
              if (selectedSlab) {
                dispatch.sms.setState({
                  selected_pricing: {
                    price: selectedSlab.price,
                    credits: selectedSlab.attribution_limit,
                  },
                });
              }
            }
          }
          break;
        case 'finish':
          dispatch.sms.setState({
            isLoading: false,
            isOnboardingFinished: false,
            isOnboardingCompleted: true,
            sender_id_type: data.sender_id_type,
          });
          break;
        default:
      }
      dispatch.sms.setState({
        isLoading: false,
      });
    },

    async setStep(
      payload: { current_step: string; click_action: string },
      rootState,
    ) {
      dispatch.sms.setState({
        isLoading: true,
        isOnboardingFinished: true,
      });

      const { sms } = rootState;
      let data = {};

      switch (payload.current_step) {
        case 'about':
          if (payload.click_action === 'next') {
            dispatch.sms.setState({
              isNextLoading: true,
            });
          } else {
            dispatch.sms.setState({
              isPreviousLoading: true,
            });
          }
          data = payload;
          break;
        case 'verify-store':
          if (payload.click_action === 'next') {
            dispatch.sms.setState({
              isNextLoading: true,
            });
          } else {
            dispatch.sms.setState({
              isPreviousLoading: true,
            });
          }
          data = {
            current_step: payload.current_step,
            click_action: payload.click_action,
            email: sms.onboarding.email,
            talon: sms.talon,
          };
          break;
        case 'verify-phone':
          if (payload.click_action === 'next') {
            dispatch.sms.setState({
              isNextLoading: true,
            });
          } else {
            dispatch.sms.setState({
              isPreviousLoading: true,
            });
          }
          data = {
            current_step: payload.current_step,
            click_action: payload.click_action,
            phone_number: sms.onboarding.phone_number,
          };
          break;
        case 'verify-otp':
          if (payload.click_action === 'previous') {
            dispatch.sms.setState({
              isPreviousLoading: true,
            });
          }
          data = {
            current_step: payload.current_step,
            click_action: payload.click_action,
            phone_number: sms.onboarding.phone_number,
            otp: Number(sms.otp),
          };
          break;
        case 'data-sync':
          dispatch.sms.setState({
            isNextLoading: true,
          });
          data = payload;
          break;
        case 'target-audience':
          if (payload.click_action === 'next') {
            dispatch.sms.setState({
              isNextLoading: true,
            });
          } else {
            dispatch.sms.setState({
              isPreviousLoading: true,
            });
          }
          data = {
            current_step: payload.current_step,
            click_action: payload.click_action,
            target_audience: sms.onboarding.countries,
          };
          break;
        case 'manage-compliance':
          if (payload.click_action === 'next') {
            dispatch.sms.setState({
              isNextLoading: true,
            });
            if (sms.compliance_type === 'toll-free') {
              data = {
                current_step: payload.current_step,
                click_action: payload.click_action,
                sender_id_type: 'tfn',
              };
            } else if (sms.compliance_type === 'shortcode') {
              data = {
                current_step: payload.current_step,
                click_action: payload.click_action,
                sender_id_type: 'short_code',
              };
            }
          } else {
            dispatch.sms.setState({
              isPreviousLoading: true,
            });
            data = payload;
          }
          break;
        case 'pricing':
          if (payload.click_action === 'next') {
            dispatch.sms.setState({
              isNextLoading: true,
            });
          }
          if (payload.click_action === 'previous') {
            dispatch.sms.setState({
              isPreviousLoading: true,
            });
          }
          if (payload.click_action === 'skip') {
            dispatch.sms.setState({
              isSkipLoading: true,
            });
            data = {
              current_step: payload.current_step,
              click_action: 'next',
              selected_sms_slab_sku: null,
            };
          } else {
            data = {
              current_step: payload.current_step,
              click_action: payload.click_action,
              selected_sms_slab_sku: sms.selected_price_data.selected_slab,
            };
          }
          break;
        default:
          data = {};
      }
      const response = await setStepAPI(data);

      if (payload.current_step === 'data-sync') {
        dispatch.subAccount.getSubAccount();
      }

      if (response.data.step === 'finish') {
        dispatch.sms.setState({
          isOnboardingFinished: false,
          isOnboardingCompleted: true,
          sender_id_type: response.data.sender_id_type,
        });
        if (response.data.redirect_to_confirm_plan) {
          Router.push(response.data.confirm_plan_url);
        } else {
          Router.push('/sms?home=true');
        }
      } else {
        dispatch.sms.setState({
          isOnboardingFinished: false,
        });
        Router.push(`/sms/onboarding/${response.data.step}`);
      }

      dispatch.sms.setState({
        isLoading: false,
        isNextLoading: false,
        isPreviousLoading: false,
        isSkipLoading: false,
      });
    },

    async fraudDetectionPhone(
      payload: { current_step: string; click_action: string },
      rootState,
    ) {
      dispatch.sms.setState({
        isLoading: true,
        isNextLoading: true,
      });

      const { sms } = rootState;
      const payload_to_send = {
        current_step: payload.current_step,
        click_action: payload.click_action,
        phone_number: sms.onboarding.phone_number,
      };
      const { data, error } = await setStepAPI(payload_to_send);

      dispatch.sms.setState({
        isLoading: false,
        isNextLoading: false,
      });

      if (error) {
        if (
          typeof error === 'object' &&
          error?.error_type === 'TooManySendAttempts'
        ) {
          dispatch.saveToast.showError('too_may_send_attempts');
        }
        if (
          typeof error === 'object' &&
          error?.error_type === 'PhoneVerificationFailed'
        ) {
          dispatch.saveToast.showError('phone_verification_error');
        }
        if (
          typeof error === 'object' &&
          error?.error_type === 'SendingOTPFailed'
        ) {
          dispatch.saveToast.showError('sending_otp_failed');
        }
      } else {
        Router.push(`/sms/onboarding/${data.step}`);
      }
    },

    async verifyOTP(
      payload: { current_step: string; click_action: string },
      rootState,
    ) {
      dispatch.sms.setState({
        isLoading: true,
      });
      const { sms } = rootState;
      let payload_to_send = {};

      if (payload.click_action === 'redo') {
        dispatch.sms.setState({
          isResendLoading: true,
        });
        payload_to_send = {
          current_step: payload.current_step,
          click_action: payload.click_action,
          phone_number: sms.onboarding.phone_number,
        };
      } else if (payload.click_action === 'next') {
        dispatch.sms.setState({
          isNextLoading: true,
        });
        payload_to_send = {
          current_step: payload.current_step,
          click_action: payload.click_action,
          phone_number: sms.onboarding.phone_number,
          otp: Number(sms.otp),
        };
      }
      const { data, error } = await setStepAPI(payload_to_send);

      dispatch.sms.setState({
        isLoading: false,
        isNextLoading: false,
        isResendLoading: false,
      });

      if (typeof error === 'object' && error?.error_type === 'InvalidOTP') {
        dispatch.saveToast.showError('invalid_otp_provided');
      }

      if (
        typeof error === 'object' &&
        error?.error_type === 'AttemptLimitExceeded'
      ) {
        dispatch.saveToast.showError('attempt_limit_exceeded');
      }
      if (typeof error === 'object' && error?.error_type === 'OTPExpired') {
        dispatch.saveToast.showError('otp_expired');
      } else if (data.step) {
        dispatch.saveToast.showDone('otp_verification_success');
        Router.push(`/sms/onboarding/${data.step}`);
      }
    },

    async updateRevenueTimeFilter({
      start_date,
      end_date,
      timeFilter,
    }: {
      start_date: Date;
      end_date: Date;
      timeFilter: TimeFilter;
    }) {
      const startDate = start_date.toISOString().split('T')[0];
      const endDate = end_date.toISOString().split('T')[0];

      const { data, error } = await getSMSStatsTimeFilter(startDate, endDate);

      if (error) {
        dispatch.saveToast.showError('error_sms_stats');
      }
      dispatch.sms.setState({
        smsStats: {
          campaign: {
            total_revenue: data.sms.campaign.total_revenue,
            attributed_revenue: data.sms.campaign.attributed_revenue,
          },
          automation: {
            total_revenue: data.sms.automation.total_revenue,
            attributed_revenue: data.sms.automation.attributed_revenue,
          },
        },
      });

      dispatch.sms.setState({ revenueTimeFilter: timeFilter });
    },

    async updateSubscriberTimeFilter({
      start_date,
      end_date,
      timeFilter,
    }: {
      start_date: Date;
      end_date: Date;
      timeFilter: TimeFilter;
    }) {
      const startDate = start_date.toISOString().split('T')[0];
      const endDate = end_date.toISOString().split('T')[0];

      const { data, error } = await getSMSStatsTimeFilter(startDate, endDate);

      if (error) {
        dispatch.saveToast.showError('error_sms_stats');
      }
      dispatch.sms.setState({
        smsStats: {
          campaign: {
            total_revenue: data.sms.campaign.total_revenue,
            attributed_revenue: data.sms.campaign.attributed_revenue,
          },
          automation: {
            total_revenue: data.sms.automation.total_revenue,
            attributed_revenue: data.sms.automation.attributed_revenue,
          },
        },
      });

      dispatch.sms.setState({ subscriberTimeFilter: timeFilter });
    },
    async getSMSStats() {
      const { data, error } = await getSMSStatsAllTime();

      if (error) {
        dispatch.saveToast.showError('error_sms_stats');
      }

      dispatch.sms.setState({
        smsStats: {
          campaign: {
            total_revenue: data.sms.campaign.total_revenue,
            attributed_revenue: data.sms.campaign.attributed_revenue,
          },
          automation: {
            total_revenue: data.sms.automation.total_revenue,
            attributed_revenue: data.sms.automation.attributed_revenue,
          },
        },
      });
    },
  }),

  reducers: {
    setState(state, payload: Partial<SmsModelState>) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export default sms;
