import { debugLog, logErrorToSentry } from '../debug-utils';
import { applyWaterMark } from '../utils';
import { post } from './axios';
import { signS3 } from './misc';

type SignS3Response = {
  data: {
    s3data: { fields: { [s: string]: string }; url: string };
    url: string;
  };
  error: string;
};

const runErrorHandler = (message: string, error) => {
  logErrorToSentry({ error });
  debugLog({
    message,
    data: {
      error,
    },
  });

  return {
    data: null,
    error: error || 'Unable to upload image. Please try again later',
  };
};

const uploadImage = async (file: File, watermark = false) => {
  const {
    data: { s3data, url },
    error: fetchURLError,
  }: SignS3Response = await signS3({
    filename: file.name,
    filetype: file.type,
  });

  if (fetchURLError)
    return runErrorHandler(
      'Error retrieving S3 URL from signS3',
      fetchURLError,
    );

  // prepare FormData
  const formData = new FormData();
  Object.entries(s3data.fields).forEach(entry => formData.append(...entry));
  if (watermark) {
    const watermarkedFile = await applyWaterMark(file);
    formData.append('file', watermarkedFile);
  } else {
    formData.append('file', file);
  }

  const { error: uploadToS3Error } = await post(s3data.url, formData, {
    baseURL: null,
  });

  if (uploadToS3Error) {
    return runErrorHandler('Error uploading image to S3', uploadToS3Error);
  }

  return { data: url, error: null };
};

export default uploadImage;
