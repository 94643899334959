import { Models } from '@rematch/core';
import banners from 'src/components/Banners/model';
import reportSaver from 'src/components/ReportSaver/models';
import activityFeed from 'src/modules/activity-feed/models';
import automationFlow from 'src/modules/automation-flows/models';
import abandonedCartAutomation from 'src/modules/automation/models/abandoned-cart';
import automationStats from 'src/modules/automation/models/automationStats';
import backInStockAutomation from 'src/modules/automation/models/back-in-stock';
import browseAbandonmentAutomation from 'src/modules/automation/models/browse-abandoment';
import priceDropAutomation from 'src/modules/automation/models/price-drop';
import shippingAutomation from 'src/modules/automation/models/shipping';
import welcomeAutomation from 'src/modules/automation/models/welcome';
import campaignCreator from 'src/modules/campaign-creator/models';
import campaigns from 'src/modules/campaigns/models';
import contentCalendar from 'src/modules/content-calendar/models';
import email from 'src/modules/email-onboarding/models';
import gettingStarted from 'src/modules/getting-started/models';
import integratedDashboard from 'src/modules/home/models';
import integratedOnboarding from 'src/modules/integrated-onboarding/models';
import integrations from 'src/modules/integrations/models';
import brevomfe from 'src/modules/mfe/models';
import notificationCreator from 'src/modules/notification-creator/models';
import onboarding from 'src/modules/onboarding/models';
import aiEditor from 'src/modules/optins/components/OptinFlowEditor/FormWidgetEditorAddon/AIEditor/models';
import formWidgetEditor from 'src/modules/optins/components/OptinFlowEditor/models';
import optins from 'src/modules/optins/models';
import pricingPreview from 'src/modules/pricing-preview/models';
import pricingV2 from 'src/modules/pricing-v2/models';
import pricingV3 from 'src/modules/pricing-v3/models';
import pricing from 'src/modules/pricing/models';
import promotionPopups from 'src/modules/promotion-popups/models';
import segments from 'src/modules/segments/models';
import settings from 'src/modules/settings/models';
import sms from 'src/modules/sms/models';
import subscribers from 'src/modules/subscribers/models';
import home from 'src/modules/webpush/models';
import common from './common';
import saveToast from './save-toast';
import subAccount from './subAccount';
import user from './user';

export default {
  common,
  saveToast,
  onboarding,
  campaigns,
  campaignCreator,
  notificationCreator,
  settings,
  abandonedCartAutomation,
  backInStockAutomation,
  browseAbandonmentAutomation,
  priceDropAutomation,
  shippingAutomation,
  welcomeAutomation,
  segments,
  formWidgetEditor,
  optins,
  home,
  integrations,
  automationFlow,
  pricing,
  pricingV2,
  pricingV3,
  pricingPreview,
  subscribers,
  promotionPopups,
  activityFeed,
  gettingStarted,
  reportSaver,
  automationStats,
  user,
  banners,
  email,
  sms,
  subAccount,
  integratedOnboarding,
  integratedDashboard,
  brevomfe,
  contentCalendar,
  aiEditor,
};

export interface RootModel extends Models<RootModel> {
  common: typeof common;
  saveToast: typeof saveToast;
  onboarding: typeof onboarding;
  campaigns: typeof campaigns;
  campaignCreator: typeof campaignCreator;
  notificationCreator: typeof notificationCreator;
  settings: typeof settings;
  abandonedCartAutomation: typeof abandonedCartAutomation;
  backInStockAutomation: typeof backInStockAutomation;
  browseAbandonmentAutomation: typeof browseAbandonmentAutomation;
  priceDropAutomation: typeof priceDropAutomation;
  shippingAutomation: typeof shippingAutomation;
  welcomeAutomation: typeof welcomeAutomation;
  segments: typeof segments;
  formWidgetEditor: typeof formWidgetEditor;
  optins: typeof optins;
  home: typeof home;
  integrations: typeof integrations;
  automationFlow: typeof automationFlow;
  pricing: typeof pricing;
  pricingV2: typeof pricingV2;
  pricingV3: typeof pricingV3;
  pricingPreview: typeof pricingPreview;
  subscribers: typeof subscribers;
  promotionPopups: typeof promotionPopups;
  activityFeed: typeof activityFeed;
  gettingStarted: typeof gettingStarted;
  reportSaver: typeof reportSaver;
  automationStats: typeof automationStats;
  user: typeof user;
  banners: typeof banners;
  email: typeof email;
  sms: typeof sms;
  subAccount: typeof subAccount;
  integratedOnboarding: typeof integratedOnboarding;
  integratedDashboard: typeof integratedDashboard;
  brevomfe: typeof brevomfe;
  contentCalendar: typeof contentCalendar;
  aiEditor: typeof aiEditor;
}
