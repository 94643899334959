import { createModel } from '@rematch/core';
import { deleteCampaignAPI } from 'src/lib/api/campaigns';
import {
  deleteCalendarEmailCampaignAPI,
  fetchCalendarCampaignContentAPI,
  fetchCalendarChecklistAPI,
  fetchCalendarEmailCampaignsAPI,
  fetchCalendarFestivalsAPI,
  fetchCalendarWebpushCampaignsAPI,
  postCalendarChecklistTaskAPI,
} from 'src/lib/api/content-calendar';
import { ChannelType } from 'src/lib/constants';
import { RootModel } from 'src/store/models';
import {
  formatChecklistItems,
  formatEmailAndSmsCampaigns,
  formatFestivals,
  formatWebpushCampaigns,
} from '../utils';
import { CHECKLIST_TASK_STATUS, OnboardingStep } from './constants';

export interface Festival {
  id: number;
  start: Date;
  end: Date;
  image: string;
  created_at: Date;
  modified_at: Date;
  title: string;
  description: string;
  window_image: string;
  android_image: string;
  mac_image: string;
  is_global: boolean;
  countries: string[];
  start_date: Date;
  end_date: Date;
  source: string;
  status: string;
}

export interface WebpushCampaign {
  id: number;
  title: string;
  description: string;
  redirect_url: string;
  icon: string;
  image: null;
  mobile_image: null;
  images: {
    mac_image: string;
  };
  scheduled_time: number;
  end_campaign_time: null;
  ttl: number;
  labels: any[];
  actions: any[];
  delivered: number;
  ctr: number;
  closed: number;
  name: string;
  clicks: number;
  body_clicks: number;
  cta1_clicks: number;
  cta2_clicks: number;
  smart_delivery: boolean;
  created_at: Date;
  modified_at: Date;
  dispatched_at: Date;
  is_sent: boolean;
  segment_id: number;
  is_scheduled: boolean;
  status: string;
  total_targeted_subscribers: number;
  type: string;
  discount_code: null;
  template_id: null;
  source: string;
  channel: string;
  dispatched_time: Date;
}

export interface ContentCalendarState {
  allFestivals: Festival[];
  allWebpushCampaigns: WebpushCampaign[];
  allChecklists: ChecklistItem[];
  allEmailCampaigns: EmailCampaign[];
  isLoading: boolean;
  selectedFestival: Festival | null;
  isPreviewOpen: boolean;
  isContentGenerating: boolean;
  showOnboarding: boolean;
  selectedChecklistIds: number[];
  isTaskLoading: boolean;
  activePopoverId: null | number;
  loadingTaskId: null | number;
  selectedDate: Date | null;
  isFestivalSelected: boolean;
  selectedChannels: string[];
  currentTourStep: OnboardingStep;
}

export interface ChecklistTitles {
  id: number;
  title: string;
}

export interface ChecklistItem {
  id: string;
  title: string;
  description: string;
  primaryButtonUrls: string;
  primaryButtonText: string;
  isCtaPrimary: boolean;
  status: string;
  checklistId: number;
  checklistTitle: string;
  isChecklistItem: boolean;
  start: Date;
  end: Date;
  allDay: boolean;
}

export interface EmailCampaign {
  title: string;
  start: Date;
  end: Date;
  allDay: boolean;
  id: number;
  emailCampaignId: number;
  isSent: boolean;
  isCampaign: boolean;
  isFestival: boolean;
  priority: number;
  channel: string;
}

const initialState: ContentCalendarState = {
  allFestivals: [],
  allWebpushCampaigns: [],
  allChecklists: [],
  allEmailCampaigns: [],
  isLoading: true,
  selectedFestival: null,
  isPreviewOpen: false,
  isContentGenerating: false,
  showOnboarding: false,
  selectedChecklistIds: [],
  isTaskLoading: false,
  loadingTaskId: null,
  activePopoverId: null,
  selectedDate: null,
  isFestivalSelected: false,
  selectedChannels: [ChannelType.WEBPUSH, ChannelType.EMAIL, ChannelType.SMS],
  currentTourStep: OnboardingStep.WELCOME,
};

export const contentCalendar = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setAllWebpushCampaigns(state, payload: any[]) {
      return { ...state, allWebpushCampaigns: payload };
    },
    setLoading(state, payload: boolean) {
      return { ...state, isLoading: payload };
    },
    setAllFestivals(state, payload: any[]) {
      return { ...state, allFestivals: payload };
    },
    setSelectedFestival(state, payload: any | null) {
      return { ...state, selectedFestival: payload };
    },
    setIsPreviewOpen(state, payload: boolean) {
      return { ...state, isPreviewOpen: payload };
    },
    setIsContentGenerating(state, payload: boolean) {
      return { ...state, isContentGenerating: payload };
    },
    setShowOnboarding(state, payload: boolean) {
      return { ...state, showOnboarding: payload };
    },
    setAllChecklists(state, payload: any[]) {
      return { ...state, allChecklists: payload };
    },
    setSelectedChecklistIds(state, payload: number[]) {
      return { ...state, selectedChecklistIds: payload };
    },
    toggleChecklist(state, checklistId: number) {
      const selectedIds = state.selectedChecklistIds.includes(checklistId)
        ? state.selectedChecklistIds.filter(id => id !== checklistId)
        : [...state.selectedChecklistIds, checklistId];
      return { ...state, selectedChecklistIds: selectedIds };
    },
    setIsTaskLoading(
      state,
      payload: { isLoading: boolean; taskId: number | null },
    ) {
      return {
        ...state,
        isTaskLoading: payload.isLoading,
        loadingTaskId: payload.taskId,
      };
    },
    setActivePopoverId(state, payload: number | null) {
      return { ...state, activePopoverId: payload };
    },
    setAllEmailCampaigns(state, payload: any[]) {
      return { ...state, allEmailCampaigns: payload };
    },
    setSelectedDate(state, payload: Date | null) {
      return { ...state, selectedDate: payload };
    },
    setIsFestivalSelected(state, payload: boolean) {
      return { ...state, isFestivalSelected: payload };
    },
    setSelectedChannels(state, payload: string[]) {
      return { ...state, selectedChannels: payload };
    },
    setCurrentTourStep(state, payload: number) {
      return { ...state, currentTourStep: payload };
    },
  },
  effects: dispatch => ({
    async fetchCalendarData() {
      this.setLoading(true);

      const [
        festivalsDataResult,
        campaignsDataResult,
        checklistDataResult,
        emailCampaignsDataResult,
      ] = await Promise.all([
        fetchCalendarFestivalsAPI(),
        fetchCalendarWebpushCampaignsAPI(),
        fetchCalendarChecklistAPI(),
        fetchCalendarEmailCampaignsAPI(),
      ]);

      if (
        festivalsDataResult.error ||
        campaignsDataResult.error ||
        checklistDataResult.error ||
        emailCampaignsDataResult.error
      ) {
        dispatch.saveToast.showError('error_fetching_calendar_data');
        this.setLoading(false);
        return;
      }

      const formattedFestivals = formatFestivals(
        festivalsDataResult?.data || [],
      );
      const formattedWebpushCampaigns = formatWebpushCampaigns(
        campaignsDataResult?.data || [],
      );
      const formattedChecklists = formatChecklistItems(
        checklistDataResult?.data || [],
      );
      const formattedEmailAndSmsCampaigns = formatEmailAndSmsCampaigns(
        emailCampaignsDataResult?.data || [],
      );

      this.setAllChecklists(formattedChecklists);
      this.setAllFestivals(formattedFestivals);
      this.setAllWebpushCampaigns(formattedWebpushCampaigns);
      this.setAllEmailCampaigns(formattedEmailAndSmsCampaigns);

      // Select all checklists by default
      this.setSelectedChecklistIds(formattedChecklists.map(c => c.checklistId));

      this.setLoading(false);
    },
    async handleDeleteWebpushCampaign(campaignId: number) {
      const { data, error } = await deleteCampaignAPI(campaignId);

      if (error) {
        dispatch.saveToast.showError('delete_campaign_failed');
        return;
      }

      if (data && data.id === campaignId) {
        this.fetchWebpushCampaigns();
        dispatch.saveToast.showDone('Campaign successfully deleted');
      }
    },
    async deleteEmailCampaign(campaignId: number) {
      const { status } = await deleteCalendarEmailCampaignAPI(campaignId);

      if (status === 204) {
        this.fetchEmailCampaigns();
        dispatch.saveToast.showDone('Campaign successfully deleted');
        return;
      }
      dispatch.saveToast.showError('delete_campaign_failed');
    },
    async fetchWebpushCampaigns() {
      const { data, error } = await fetchCalendarWebpushCampaignsAPI();

      if (error) {
        dispatch.saveToast.showError('error_fetching_campaigns');
        return;
      }

      const formattedWebpushCampaigns = formatWebpushCampaigns(data);
      this.setAllWebpushCampaigns(formattedWebpushCampaigns);
    },
    async fetchEmailCampaigns() {
      const { data, error } = await fetchCalendarEmailCampaignsAPI();
      if (error) {
        dispatch.saveToast.showError('error_fetching_campaigns');
        return;
      }

      const formattedEmailCampaigns = formatEmailAndSmsCampaigns(data);
      this.setAllEmailCampaigns(formattedEmailCampaigns);
    },
    async toggleChecklistTask(
      payload: { taskId: number; checked: boolean },
      rootState: any,
    ) {
      this.setIsTaskLoading({ isLoading: true, taskId: payload.taskId });

      const { error, status } = await postCalendarChecklistTaskAPI({
        taskId: payload.taskId,
        status: payload.checked
          ? CHECKLIST_TASK_STATUS.DONE
          : CHECKLIST_TASK_STATUS.TODO,
      });

      if (error || status !== 201) {
        this.setIsTaskLoading({ isLoading: false, taskId: null });
        dispatch.saveToast.showError('error_finishing_checklist_task');
        return;
      }

      const { allChecklists } = rootState.contentCalendar;
      const updatedChecklists = allChecklists.map(checklist => {
        if (checklist.id === Number(payload.taskId)) {
          return {
            ...checklist,
            status: payload.checked
              ? CHECKLIST_TASK_STATUS.DONE
              : CHECKLIST_TASK_STATUS.TODO,
          };
        }
        return checklist;
      });
      this.setAllChecklists(updatedChecklists);
      this.setIsTaskLoading({ isLoading: false, taskId: null });
    },
    async handleFestivalClick(payload: { eventInfo: any }, rootState: any) {
      const { user } = rootState;
      this.setIsContentGenerating(true);
      this.setIsPreviewOpen(true);
      this.setSelectedFestival(payload.eventInfo.event);

      const response = await fetchCalendarCampaignContentAPI(
        payload.eventInfo.event.id,
        ChannelType.WEBPUSH,
      );

      if (response.error) {
        this.setIsContentGenerating(false);
        dispatch.saveToast.showError('error_fetching_campaigns');
        return;
      }

      // Update notification creator state
      dispatch.notificationCreator.setProp({
        prop: 'title',
        value: response.data.title,
      });
      dispatch.notificationCreator.setProp({
        prop: 'message',
        value: response.data.description,
      });
      dispatch.notificationCreator.setProp({
        prop: 'icon',
        value: user.user.website.company_logo,
      });
      dispatch.notificationCreator.setHeroImage({
        macos: response.data.mac_image,
        mobile: response.data.android_image,
        desktop: response.data.window_image,
      });

      // Set the scheduled time to 10 AM on the event's start date
      const scheduledDate = new Date(payload.eventInfo.event.start);
      scheduledDate.setHours(10, 0, 0, 0); // Set to 10:00:00 AM

      // Update campaign creator state
      dispatch.campaignCreator.setProp({
        prop: 'scheduledTime',
        value: scheduledDate,
      });
      dispatch.campaignCreator.setProp({
        prop: 'sendType',
        value: 'scheduled',
      });
      dispatch.campaignCreator.setProp({
        prop: 'campaignType',
        value: 'regular',
      });
      dispatch.campaignCreator.setProp({
        prop: 'source',
        value: 'dashboard_content_calendar',
      });

      this.setIsContentGenerating(false);
    },
    checkFirstTimeVisit() {
      const hasVisited = localStorage.getItem('hasVisitedCalendar');
      if (!hasVisited) {
        this.setShowOnboarding(true);
        localStorage.setItem('hasVisitedCalendar', 'true');
      }
    },
    closeOnboarding() {
      this.setShowOnboarding(false);
    },
    changeActivePopoverId(payload: number | null, rootState) {
      const { activePopoverId } = rootState.contentCalendar;
      if (activePopoverId === payload) {
        this.setActivePopoverId(null);
        return;
      }
      this.setActivePopoverId(payload);
    },
    toggleChannel(channel: string, rootState) {
      const { selectedChannels } = rootState.contentCalendar;

      const filteredChannels = selectedChannels.includes(channel)
        ? selectedChannels.filter(c => c !== channel)
        : [...selectedChannels, channel];

      dispatch.contentCalendar.setSelectedChannels(filteredChannels);
    },
  }),
});

export default contentCalendar;
