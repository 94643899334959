/* eslint-disable no-bitwise */
import { CSSProperties } from 'react';
import { FormWidgetType } from 'src/lib/constants';
import {
  applyChangesToNodeTree,
  getTreeNodeById,
} from 'src/modules/optins/util/treeUtils';
import {
  DEFAULT_LOGO_HEIGHT,
  FONT_SIZE_MAPPING,
  ReservedContainerNodeId,
  ReservedElementNodeId,
  ROOT_NODE_ID,
  SIZES,
} from './constants';
import {
  BackgroundDimensions,
  BaseTreeNode,
  Direction,
  Fill,
  FormWidgetTheme,
  MediaNodeType,
  MediaPosition,
  Size,
  TreeNode,
  TreeNodeAlignment,
  TreeNodeByType,
  TreeNodeType,
} from './types';

export const transformAlignmentToFlexProps = (
  alignment?: TreeNodeAlignment,
  direction?: Direction,
): CSSProperties => {
  if (!alignment || alignment?.length === 0) {
    return {};
  }

  const flexProps: Record<string, string> = {};
  // [horizontal alignment, vertical alignment]
  const alignmentArr =
    typeof alignment === 'string' ? ['left', alignment] : alignment;

  if (direction === 'LR') {
    alignmentArr.forEach((alignmentType, idx) => {
      switch (alignmentType) {
        case 'center':
          flexProps.alignItems = 'center';
          if (idx === 0) {
            flexProps.textAlign = 'center';
          }
          break;
        case 'left':
          flexProps.justifyContent = 'flex-start';
          if (idx === 0) {
            flexProps.textAlign = 'left';
          }
          break;
        case 'right':
          flexProps.justifyContent = 'flex-end';
          if (idx === 0) {
            flexProps.textAlign = 'right';
          }
          break;
        case 'bottom':
          flexProps.alignItems = 'flex-end';
          break;
        case 'top':
          flexProps.alignItems = 'flex-start';
          break;
        default:
          return flexProps;
      }

      return {};
    });

    return flexProps;
  }

  alignmentArr.forEach((alignmentType, idx) => {
    switch (alignmentType) {
      case 'center':
        flexProps.justifyContent = 'center';
        if (idx === 0) {
          flexProps.textAlign = 'center';
          flexProps.alignItems = 'center';
        }
        break;
      case 'left':
        flexProps.alignItems = 'flex-start';
        if (idx === 0) {
          flexProps.textAlign = 'left';
        }
        break;
      case 'right':
        flexProps.alignItems = 'flex-end';
        if (idx === 0) {
          flexProps.textAlign = 'right';
        }
        break;
      case 'bottom':
        flexProps.justifyContent = 'flex-end';
        break;
      case 'top':
        flexProps.justifyContent = 'flex-start';
        break;

      default:
        return flexProps;
    }

    return {};
  });

  return flexProps;
};

export const getBackgroundImageProps = ({
  url,
  bgColor,
  bgHidden,
  isRootNode,
  bgDims,
}: {
  url?: string;
  bgColor?: string;
  bgHidden?: boolean;
  isRootNode?: boolean;
  bgDims?: BackgroundDimensions;
}): CSSProperties => {
  const computedBgColor = isRootNode ? bgColor || '#fff' : bgColor || 'inherit';

  const bgDimsProps = bgDims ? getBgDimsProps(bgDims) : {};

  if (!url || bgHidden) {
    return {
      background: computedBgColor,
      backgroundImage: 'unset',
    };
  }

  return url
    ? {
        backgroundImage: `url("${url}") ${
          isRootNode
            ? `, linear-gradient(to right, ${computedBgColor}, ${computedBgColor})`
            : ''
        }`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        ...bgDimsProps,
      }
    : {};
};

export const getFillAmount = (fill?: Fill) => {
  if (typeof fill === 'string') {
    return fill;
  }

  if (typeof fill === 'boolean') {
    return fill ? '50%' : '';
  }

  return '50%';
};

export const getInputTypeByNode = (nodeId: string) => {
  switch (nodeId) {
    case 'email':
      return 'email';
    case 'phone':
      return 'text';
    default:
      return 'text';
  }
};

export const getFontProps = (
  size?: Size,
  defaultSize = 14,
): React.CSSProperties => {
  const lineHeightMultiplier = 1.2;
  const defaultProps: React.CSSProperties = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: `${defaultSize}px`,
    lineHeight: `${defaultSize * lineHeightMultiplier}px`,
    paddingBlockEnd: '0px',
  };

  if (!size) {
    return defaultProps;
  }

  const bottomPadding =
    {
      '4xl': '10px',
      '3xl': '10px',
      '2xl': '8px',
      xl: '6px',
      lg: '2px',
      md: '0px',
      sm: '0px',
      xs: '0px',
      0: '0px',
    }[size] || '0px';

  const baseSize = FONT_SIZE_MAPPING[size] || defaultSize;

  const fontWeight = baseSize > 16 ? 'bold' : 'normal';

  return {
    ...defaultProps,
    fontSize: `${baseSize}px`,
    lineHeight: `${baseSize * lineHeightMultiplier}px`,
    fontWeight,
    paddingBlockEnd: bottomPadding,
  };
};

export const isHeadingText = (size: Size) => {
  return FONT_SIZE_MAPPING[size] >= FONT_SIZE_MAPPING['2xl'];
};

export const getTextColorProps = (color?: string) => {
  return color || '#000';
};

export const getSizePropByIndex = (index: number): Size => SIZES[index] ?? 'md';

export const getIndexBySizeProp = (size: Size): number =>
  SIZES.indexOf(size) === -1 ? 3 : SIZES.indexOf(size);

export const getPaddingProps = (size?: Size): React.CSSProperties => {
  if (!size) {
    return { padding: '6px' };
  }

  const baseSize =
    {
      xs: 2,
      sm: 4,
      md: 6,
      lg: 8,
      xl: 10,
      '2xl': 12,
      '3xl': 14,
      '4xl': 16,
      '5xl': 20,
      '6xl': 26,
      '0': 0,
    }[size] ?? 6;

  return {
    padding: `${baseSize}px`,
  };
};

export const getBorderWidthProps = (borderWidth?: Size) => {
  if (!borderWidth) {
    return 0;
  }

  const baseWidth =
    {
      '0': 0,
      xs: 1,
      sm: 2,
      md: 3,
      lg: 4,
      xl: 5,
      '2xl': 6,
      '3xl': 7,
      '4xl': 8,
      '5xl': 9,
      '6xl': 10,
    }[borderWidth] ?? 0;

  return baseWidth;
};

export const getBorderProps = (
  borderCol?: string,
  borderWidth?: Size,
): CSSProperties => {
  if (!borderCol || !borderWidth) {
    return {};
  }

  return {
    border: `${getBorderWidthProps(borderWidth)}px solid ${borderCol}`,
  };
};

/* isEmoji arg and width: 100% is made only for better editor experience
 * in storefront, the width is set to unset for all nodes except flyout
 */
export const getTextOverflowProps = (
  isFlyout: boolean,
  isEmoji: boolean,
): CSSProperties => {
  if (isEmoji) {
    return {
      width: 'unset',
    };
  }

  return {
    whiteSpace: isFlyout ? 'nowrap' : 'normal',
    // leave some gap for the close icon in teaser
    width: isFlyout ? 'calc(100% - 25px)' : '100%',
  };
};

export const getFlexDirectionProps = (
  isSmallScreen: boolean,
  dir?: Direction,
): CSSProperties => {
  if (isSmallScreen) {
    return {
      flexDirection: 'column',
    };
  }

  return {
    flexDirection: dir === 'LR' ? 'row' : 'column',
  };
};

export const getAdjustedInnerRadiusProps = (
  outerRadius?: Size,
  padding?: Size,
) => {
  const outerRadiusValue = parseInt(
    getRadiusProps(outerRadius).split('px')[0],
    10,
  );
  const paddingValue = parseInt(getRadiusProps(padding).split('px')[0], 10);

  const adjustedRadius = Math.abs(outerRadiusValue - paddingValue);

  return adjustedRadius === 0 ? paddingValue / 2 : adjustedRadius;
};

const getBgDimsProps = (bgDims?: BackgroundDimensions): React.CSSProperties => {
  const isAutoZoom = !bgDims?.type || bgDims?.type === 'auto' || !bgDims?.zoom;

  return {
    backgroundPosition: `${bgDims?.pos?.[0] || 0}% ${bgDims?.pos?.[1] || 0}%`,
    backgroundSize: isAutoZoom ? 'cover' : `${bgDims?.zoom}%`,
  };
};

export const getBoxRenderProps = (
  node: TreeNodeByType<TreeNodeType.BOX>,
  isSmallScreen: boolean,
  rootNodeAttr?: TreeNodeByType<TreeNodeType.BOX>['attr'],
): React.CSSProperties => {
  const {
    align,
    dir,
    bgUrl: bgURL,
    pad,
    bgColor,
    css,
    fill,
    hidden,
    radius,
    borderCol,
    borderWidth,
    bgDims,
    bgHidden,
  } = node.attr || {};
  const wheelNode = getTreeNodeById(node, ReservedElementNodeId.WHEEL);

  const shouldHideBgImage =
    !wheelNode && bgURL && isSmallScreen && node.id !== ROOT_NODE_ID;

  const alignmentProps = transformAlignmentToFlexProps(align, dir);
  const flexDirProps = getFlexDirectionProps(isSmallScreen, dir);
  const bgProps = !shouldHideBgImage
    ? getBackgroundImageProps({
        url: bgURL,
        bgColor,
        bgHidden,
        isRootNode: node.id === ROOT_NODE_ID,
        bgDims,
      })
    : {};
  const paddingProps = getPaddingProps(pad);
  const fillAmount = getFillAmount(fill);
  const borderProps = getBorderProps(
    borderCol || '#ffffff',
    borderWidth || '0',
  );
  let borderRadius: string | number = 0;
  let displayProp = 'flex';
  const considerHidden = typeof node.attr.hidden === 'boolean' ? hidden : false;

  if (node.id !== ROOT_NODE_ID) {
    if (considerHidden) {
      displayProp = 'none';
    } else if (shouldHideBgImage) {
      displayProp = 'none';
    }

    if (
      bgURL &&
      (!rootNodeAttr?.pad || rootNodeAttr?.pad !== '0') &&
      rootNodeAttr?.radius &&
      rootNodeAttr?.radius !== '0'
    ) {
      borderRadius = getAdjustedInnerRadiusProps(
        rootNodeAttr?.radius || '0',
        rootNodeAttr?.pad || '0',
      );
    } else if (!bgURL) {
      borderRadius = getRadiusProps(radius || '0');
    }
  } else {
    borderRadius = getRadiusProps(radius || '0');
  }

  return {
    position: 'relative',
    borderRadius,
    display: displayProp,
    flex: fillAmount,
    flexShrink: bgURL ? 0 : 1,
    // adding this because flexbox container is buggy when expanding on height of children (might change in the future)
    minHeight: bgURL ? '200px' : 'unset',
    // adding minWidth: 0 to fix overflow issue and flex shrink issue
    // ref: https://defensivecss.dev/tip/flexbox-min-content-size/
    minWidth: 0,
    width: node.id === ReservedContainerNodeId.TEXT_CONTENT ? '100%' : 'unset', // make sure text content is always full width
    overflow: node.id === ROOT_NODE_ID ? 'hidden' : 'unset',
    ...borderProps,
    ...flexDirProps,
    ...paddingProps,
    ...alignmentProps,
    ...bgProps,
    ...(css || {}),
  };
};

export const getRadiusProps = (radius?: Size): string => {
  if (radius === 'full') return '100%';
  const baseSize =
    {
      '0': 0,
      xs: 2,
      sm: 4,
      md: 6,
      lg: 8,
      xl: 10,
      '2xl': 12,
      '3xl': 14,
      '4xl': 16,
      '5xl': 20,
      '6xl': 26,
    }[radius] || 0;

  return `${baseSize}px`;
};

export const isMediaHidden = (node: TreeNode) => {
  const mediaNode = getEditableMediaNode(node, node);

  if (!mediaNode) {
    return true;
  }

  if (mediaNode.id === ROOT_NODE_ID && mediaNode.type === TreeNodeType.BOX) {
    return typeof mediaNode?.attr?.bgHidden === 'boolean'
      ? mediaNode.attr.bgHidden
      : false;
  }

  return typeof mediaNode?.attr?.hidden === 'boolean'
    ? mediaNode.attr.hidden
    : false;
};

export const getEditableMediaNode = (
  referenceNode: TreeNode,
  rootNode: TreeNode,
) => {
  const imageNode = getTreeNodeById(
    referenceNode,
    ReservedContainerNodeId.IMAGE,
  );

  if (rootNode?.type === TreeNodeType.BOX && rootNode.attr.bgUrl) {
    return rootNode;
  }

  return imageNode;
};

export const getMediaNodeType = (
  node: TreeNodeByType<TreeNodeType.BOX>,
): MediaNodeType => {
  if (node.children?.length) {
    if (node.children[0].type === TreeNodeType.WHEEL) {
      return 'game';
    }
  }

  return 'image';
};

export const getWidgetDimensionProps = (
  isSmallScreen: boolean,
  theme?: Partial<FormWidgetTheme>,
  isEmbedded?: boolean,
): Record<FormWidgetType, CSSProperties> => {
  if (isEmbedded) {
    return {
      [FormWidgetType.STEP_1]: {
        width: 'calc(100%)',
        maxWidth: '600px',
        height: theme?.fixedHeight ? `${theme?.height}px` : 'unset',
      },
      [FormWidgetType.STEP_2]: {
        width: 'calc(100%)',
        maxWidth: '600px',
        height: theme?.fixedHeight ? `${theme?.height}px` : 'unset',
      },
      [FormWidgetType.TEASER]: {
        width: '360px',
        height: '64px',
      },
    };
  }

  if (isSmallScreen) {
    return {
      [FormWidgetType.STEP_1]: {
        width: '300px',
        minHeight: '450px',
      },
      [FormWidgetType.STEP_2]: {
        width: '300px',
        minHeight: '450px',
      },
      [FormWidgetType.TEASER]: {
        width: theme?.width ? `${theme?.width}px` : '360px',
        height: '72px',
      },
    };
  }

  return {
    [FormWidgetType.STEP_1]: {
      width: theme?.width ? `${theme?.width}px` : '530px',
      minHeight: theme?.fixedHeight ? `${theme?.height}px` : '500px',
      height: theme?.fixedHeight ? `${theme?.height}px` : 'unset',
    },
    [FormWidgetType.STEP_2]: {
      width: theme?.width ? `${theme?.width}px` : '530px',
      minHeight: theme?.fixedHeight ? `${theme?.height}px` : '500px',
      height: theme?.fixedHeight ? `${theme?.height}px` : 'unset',
    },
    [FormWidgetType.TEASER]: {
      width: theme?.width ? `${theme?.width}px` : '360px',
      height: '72px',
    },
  };
};

export const isCustomFieldNode = (node: TreeNode | BaseTreeNode | string) =>
  (typeof node === 'string' ? node : node.id).startsWith(
    ReservedElementNodeId.CUSTOM_FIELD,
  );

export const isDarkColor = (hex: string) => {
  const rgb = parseInt(hex.slice(1), 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = rgb & 0xff;
  const brightness = 0.299 * r + 0.587 * g + 0.114 * b;
  return brightness < 128;
};

export const getImageHeight = (node: TreeNodeByType<TreeNodeType.IMAGE>) => {
  const { height } = node.attr;

  if (height) {
    return height;
  }

  switch (node.id) {
    case ReservedElementNodeId.LOGO:
      return DEFAULT_LOGO_HEIGHT;
    default:
      return 50;
  }
};

export const createWheelNode = (
  position: MediaPosition,
): TreeNodeByType<TreeNodeType.WHEEL> => {
  const probability = 100 / 5;

  return {
    id: ReservedElementNodeId.WHEEL,
    type: TreeNodeType.WHEEL,
    attr: {
      pos: position,
      spinText: {
        label: 'SPIN',
        color: '#164F4A',
      },
      marker: {
        bgColor: '#ffffff',
        color: '#164F4A',
      },
      slices: [
        {
          label: 'Slice 1',
          chance: probability,
          code: '',
          bgColor: '#87D3C3',
          color: '#000',
        },
        {
          label: 'Slice 2',
          chance: probability,
          code: '',
          bgColor: '#2F968D',
          color: '#000',
        },
        {
          label: 'Slice 3',
          chance: probability,
          code: '',
          bgColor: '#87D3C3',
          color: '#000',
        },
        {
          label: 'Slice 4',
          chance: probability,
          code: '',
          bgColor: '#2F968D',
          color: '#000',
        },
        {
          label: 'Slice 5',
          chance: probability,
          code: '',
          bgColor: '#87D3C3',
          color: '#000',
        },
        {
          label: 'Slice 6',
          chance: 0,
          code: '',
          bgColor: '#2F968D',
          color: '#000',
        },
      ],
    },
    children: undefined,
  };
};

/**
 * augments the node tree so that the media node is always on top in mobile when the wheel is present
 */
export const augmentMediaNodeToTop = (
  rootNode: TreeNode,
  isSmallScreen: boolean,
) => {
  if (!isSmallScreen) {
    return rootNode;
  }

  const wheelNode = getTreeNodeById(rootNode, ReservedElementNodeId.WHEEL);

  if (!wheelNode) {
    return rootNode;
  }

  let updatedTree = applyChangesToNodeTree(
    ROOT_NODE_ID,
    node => {
      if (node.type === TreeNodeType.BOX) {
        node.attr = {
          ...node.attr,
          dir: 'TB',
        };

        const mediaNode = getTreeNodeById(node, ReservedContainerNodeId.IMAGE);

        if (mediaNode) {
          node.children = [
            mediaNode,
            ...(node.children || []).filter(child => child.id !== mediaNode.id),
          ];
        }
      }
    },
    { ...rootNode },
  );

  updatedTree = applyChangesToNodeTree(
    ReservedElementNodeId.WHEEL,
    node => {
      if (node.type === TreeNodeType.WHEEL) {
        node.attr = {
          ...node.attr,
          pos: 'top',
        };
      }
    },
    updatedTree,
  );

  updatedTree = applyChangesToNodeTree(
    ReservedContainerNodeId.IMAGE,
    node => {
      if (node.type === TreeNodeType.BOX) {
        node.attr = {
          ...node.attr,
          fill: '40%',
        };
      }
    },
    updatedTree,
  );

  return updatedTree;
};
